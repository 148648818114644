import { ACTIONS } from "../reducers"

export const updateFutureTenants = (list) => {
    return (dispatch) => {
        dispatch({
            type: ACTIONS.UPDATE_FUTURE_TENANTS,
            payload: list
        })
    }
}

export const updateFutureTenant = (item) => {
    return (dispatch) => {
        dispatch({
            type: ACTIONS.UPDATE_FUTURE_TENANT,
            payload: item
        })
    }
}