import React, { useState, useEffect } from 'react';

import { addItem } from "../../../services/firebase"

import PropTypes from 'prop-types';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import InputBase from '@material-ui/core/InputBase';
import SearchIcon from '@material-ui/icons/Search';
import Snackbar from '@material-ui/core/Snackbar';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';

import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import StarBorderIcon from '@material-ui/icons/StarBorder';

import MuiAlert from '@material-ui/lab/Alert';
import Rating from '@material-ui/lab/Rating';

import { makeStyles } from '@material-ui/core/styles';
import { alpha } from '@material-ui/core/styles';

import ReviewTableRow from './ReviewTableRow';
import AddReviewDialog from '../common/AddReviewDialog';

const moment = require('moment');

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    titleBar: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        color: theme.palette.common.white,
        backgroundColor: theme.palette.primary.light,
        boxShadow: "none",
        borderBottom: "1px solid",
        borderBottomColor: theme.palette.grey.A200
    },
    title: {
        flexGrow: 1,
        display: 'none',
        [theme.breakpoints.up('sm')]: {
            display: 'block',
        },
    },
    actionButtons: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        color: theme.palette.common.white,
        backgroundColor: theme.palette.success.main
    },
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: alpha(theme.palette.common.white, 0.15),
        '&:hover': {
            backgroundColor: alpha(theme.palette.common.white, 0.25),
        },
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(1),
            width: 'auto',
        },
    },
    searchIcon: {
        padding: theme.spacing(0, 2),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    inputRoot: {
        color: 'inherit',
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 0),
        paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: '12ch',
            '&:focus': {
                width: '20ch',
            },
        },
    },
    paper: {
        boxShadow: '0px 1px 1px -1px rgb(0 0 0 / 10%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(63 81 181 / 12%)'
    },
    checkBoxRoot: {
        '&:hover': {
            backgroundColor: 'transparent',
        },
    },
    icon: {
        borderRadius: 3,
        width: 16,
        height: 16,
        boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
        backgroundColor: theme.palette.common.white,
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
        '$root.Mui-focusVisible &': {
            outline: '2px auto rgba(19,124,189,.6)',
            outlineOffset: 2,
        },
        'input:hover ~ &': {
            backgroundColor: '#ebf1f5',
        },
        'input:disabled ~ &': {
            boxShadow: 'none',
            background: 'rgba(206,217,224,.5)',
        },
    },
    checkedIcon: {
        backgroundColor: theme.palette.success.main,
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
        '&:before': {
            display: 'block',
            width: 16,
            height: 16,
            backgroundImage:
                "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
                " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
                "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
            content: '""',
        },
        'input:hover ~ &': {
            backgroundColor: theme.palette.success.main,
        },
    },
    emptyStar: {
        color: "white"
    }
}));

const headCells = [
    { id: 'reviewDate', numeric: false, disablePadding: false, label: 'Date' },
    { id: 'source', numeric: false, disablePadding: false, label: 'Source' },
    { id: 'property', numeric: false, disablePadding: false, label: 'Property' },
    { id: 'name', numeric: false, disablePadding: false, label: 'Name' },
    { id: 'rating', numeric: false, disablePadding: false, label: 'Rating' },
    { id: 'tenantText', numeric: false, disablePadding: false, label: 'Tenant' },
];

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
    const { order, orderBy, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                <TableCell />
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'right' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}
                        style={{ minWidth: headCell.minWidth }}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
};

const ReviewTable = (props) => {
    const {
        list,
        page,
        rowsPerPage,
        pageChanged,
        rowsPerPageChanged,
        sources,
        properties,
        reviewCreated,
        reviewDeleted
    } = props;
    const classes = useStyles();

    // Alert

    const [error, setError] = useState();
    const [message, setMessage] = useState();

    const [notification, setNotification] = useState({
        open: false,
        vertical: 'top',
        horizontal: 'center',
    });

    const { open, vertical, horizontal } = notification;

    const closeNotification = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setNotification({ ...notification, open: false });
    };

    // Table

    const [searchParam, setSearchParam] = useState('');
    const [filteredList, setFilteredList] = useState([]);
    const [order, setOrder] = useState('desc');
    const [orderBy, setOrderBy] = useState('moveOut');
    const [source, setSource] = useState('');
    const [property, setProperty] = useState('');
    const [rating, setRating] = useState(0);
    const [resetReview, setResetReview] = useState(false);

    const sortChanged = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    function searchChanged(event) {
        setSearchParam(event.target.value);
    }

    useEffect(() => {
        const filtered = getFilteredList();
        setFilteredList(filtered);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [list, source, property, rating, searchParam]);

    const getFilteredList = () => {
        if (list instanceof Array === false) {
            return [];
        }

        const search = searchParam.toLowerCase();
        let filteredList = list.filter(item =>
            item.name.toLowerCase().includes(search) ||
            item.review.toLowerCase().includes(search)
        );

        if (source.length > 0) {
            filteredList = filteredList.filter(item => item.source === source);
        }

        if (property.length > 0) {
            filteredList = filteredList.filter(item => item.property === property);
        }

        if (rating > 0) {
            filteredList = filteredList.filter(item => item.rating >= rating);
        }

        return filteredList;
    }

    const notificationUpdated = (response) => {
        if (response) {
            setError(!response.result);
            setMessage(response.message);
            setNotification({ ...notification, open: true });
        }
    }

    // Title bar

    const sourceChanged = (event) => {
        const value = event.target.value;

        if (sources.includes(value)) {
            setSource(value);
        }
        else {
            setSource('');
        }
    };

    const propertyChanged = (event) => {
        const value = event.target.value;

        if (properties.includes(value)) {
            setProperty(value);
        }
        else {
            setProperty('');
        }
    };

    const ratingChanged = (event, newValue) => {
        setRating(newValue);
    }

    const resetRating = () => {
        setRating(0);
    }

    // Add Review

    const [showAddReviewDialog, setShowAddReviewDialog] = useState(false);

    const openAddReviewDialog = () => {
        setShowAddReviewDialog(true);
    };

    const cancelAddReviewDialog = (value) => {
        setShowAddReviewDialog(false);
        setResetReview(!resetReview);
    };

    const closeAddReviewDialog = (source, property, reviewDate, name, rating, review) => {
        setShowAddReviewDialog(false);
        addReview(source, property, reviewDate, name, rating, review);
    };

    const addReview = async (source, property, reviewDate, name, rating, review) => {

        try {
            let itm = await addItem('reviews', {
                source: source,
                property: property,
                reviewDate: reviewDate,
                name: name,
                rating: rating,
                review: review,
                createdOn: new Date()
            });

            itm.reviewDateText = moment(itm.reviewDate).format('MM/DD/YY');

            reviewCreated(itm);
            list.unshift(itm);

            notificationUpdated({ result: true, message: 'New review added' });

            setResetReview(!resetReview);
        } catch (error) {
            console.log(`Error adding note: ${error.message} `);
        }
    }

    return (
        <>
            <Snackbar
                anchorOrigin={{ vertical, horizontal }}
                open={open}
                autoHideDuration={error ? 6000 : 3000}
                onClose={closeNotification}
            >
                <Alert
                    onClose={closeNotification}
                    severity={error ? "error" : "success"}
                >
                    {message}
                </Alert>
            </Snackbar>

            <AddReviewDialog
                tenant={null}
                sources={sources}
                properties={properties}
                open={showAddReviewDialog}
                onCancel={cancelAddReviewDialog}
                onClose={closeAddReviewDialog}
                resetReview={resetReview}
            />

            <div className={classes.root}>
                <AppBar position="static" className={classes.titleBar}>
                    <Toolbar disableGutters={true}>
                        <Grid container style={{ width: '100%' }}>
                            <Grid item xs={1}>
                                <Box
                                    style={{ paddingTop: '8px' }}>
                                    <Typography className={classes.title} variant="h6" noWrap>
                                        Reviews
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={2}>
                                <Box
                                    style={{ paddingTop: '8px' }}>
                                    <Button
                                        variant="contained"
                                        className={classes.actionButtons}
                                        onClick={() => { openAddReviewDialog() }}
                                    >
                                        Add Review
                                    </Button>
                                </Box>
                            </Grid>
                            <Grid item xs={3}>
                                <FormControl>
                                    <InputLabel htmlFor="saved-sources">Source</InputLabel>
                                    <Select
                                        native
                                        onChange={sourceChanged}
                                        style={{ minWidth: '200px' }}
                                        value={source}
                                    >
                                        <option aria-label="None" value="" />
                                        {
                                            sources.map((source, index) => {
                                                return <option key={index} value={source}>{source}</option>
                                            })
                                        }
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={4}>
                                <FormControl>
                                    <InputLabel htmlFor="saved-sources">Property</InputLabel>
                                    <Select
                                        native
                                        onChange={propertyChanged}
                                        style={{ minWidth: '300px' }}
                                        value={property}
                                    >
                                        <option aria-label="None" value="" />
                                        {
                                            properties.map((property, index) => {
                                                return <option key={index} value={property}>{property}</option>
                                            })
                                        }
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={2}>
                                <Box style={{ paddingTop: '10px' }}>
                                    <Rating
                                        precision={0.5}
                                        name="filter-rating"
                                        value={rating}
                                        emptyIcon={<StarBorderIcon fontSize="inherit" className={classes.emptyStar} />}
                                        onChange={ratingChanged}
                                    />

                                    <IconButton
                                        style={{ paddingBottom: '18px' }}
                                        size="small"
                                        color="primary"
                                        onClick={resetRating}
                                    >
                                        <HighlightOffIcon />
                                    </IconButton>
                                </Box>
                            </Grid>
                        </Grid>

                        <div className={classes.search}>
                            <div className={classes.searchIcon}>
                                <SearchIcon />
                            </div>
                            <InputBase
                                placeholder="Search…"
                                classes={{
                                    root: classes.inputRoot,
                                    input: classes.inputInput,
                                }}
                                inputProps={{ 'aria-label': 'search' }}
                                value={searchParam}
                                onChange={searchChanged}
                            />
                        </div>
                    </Toolbar>
                </AppBar>
            </div>
            <Paper className={classes.paper}>
                <TableContainer>
                    <Table>
                        <EnhancedTableHead
                            classes={classes}
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={sortChanged}
                        />
                        <TableBody>
                            {stableSort(filteredList, getComparator(order, orderBy))
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row) => (
                                    <ReviewTableRow
                                        key={row.id}
                                        headCells={headCells}
                                        row={row}
                                        reviewDeleted={reviewDeleted}
                                    />
                                ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
            <TablePagination
                rowsPerPageOptions={[5, 10, 25, 100]}
                component="div"
                count={filteredList.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={pageChanged}
                onRowsPerPageChange={rowsPerPageChanged}
            />
        </>
    );
}

ReviewTable.propTypes = {
    list: PropTypes.any.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
    pageChanged: PropTypes.func.isRequired,
    rowsPerPageChanged: PropTypes.func.isRequired,
    sources: PropTypes.any.isRequired,
    properties: PropTypes.any.isRequired,
    reviewDeleted: PropTypes.func.isRequired,
};

export default ReviewTable;