import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { bindActionCreators } from 'redux';
import { actionCreators } from "../../store/index"

import { useAuth } from '../../contexts/AuthContext';

import TenantList from "./TenantList"
import UnderReview from './UnderReview';

const First3090Days = () => {
    const { currentUser } = useAuth();
    const [showPage, setShowPage] = useState(false);

    useEffect(() => {
        if (currentUser && currentUser.welcome) {
            setShowPage(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentUser]);

    const dispatch = useDispatch();
    const {
        updateTenant,
        updateFirst3090daysTenant,
        createReview,
        createSms,
        updateSms,
        deleteSms,
        createEmail,
        updateEmail,
        deleteEmail,
        createNote,
        updateNote,
        deleteNote,
        createNoteStatus,
        updateNoteStatus,
        deleteNoteStatus,
    } = bindActionCreators(actionCreators, dispatch);

    const propertyNames = (list) => {
        if (list instanceof Array) {
            return [...new Set(list.map(item => item.propertyName))];
        }
        return [];
    }

    const {
        first3090Tenants: list,
        sources,
        properties,
        sms,
        emails,
        notes,
        noteStatuses
    } = useSelector((state) => state);

    const updateTenantCache = (item) => {
        updateTenant(item);
        updateFirst3090daysTenant(item);
    }

    const reviewCreated = (item) => {
        createReview(item);
    }

    const smsCreated = (item) => {
        createSms(item);
    }

    const smsUpdated = (item) => {
        updateSms(item);
    }

    const smsDeleted = (item) => {
        deleteSms(item);
    }

    const emailCreated = (item) => {
        createEmail(item);
    }

    const emailUpdated = (item) => {
        updateEmail(item);
    }

    const emailDeleted = (item) => {
        deleteEmail(item);
    }

    const noteCreated = (item) => {
        createNote(item);
    }

    const noteUpdated = (item) => {
        updateNote(item);
    }

    const noteDeleted = (item) => {
        deleteNote(item);
    }

    const noteStatusCreated = (item) => {
        createNoteStatus(item);
    }

    const noteStatusUpdated = (item) => {
        updateNoteStatus(item);
    }

    const noteStatusDeleted = (item) => {
        deleteNoteStatus(item);
    }

    return (
        <>
            {showPage
                ?
                <>
                    {
                        propertyNames(list).sort().map(propertyName => {
                            return <TenantList
                                key={propertyName}
                                propertyName={propertyName}
                                list={list.filter(item => item.propertyName === propertyName)}
                                sources={sources}
                                properties={properties}
                                sms={sms}
                                emails={emails}
                                notes={notes}
                                noteStatuses={noteStatuses}
                                updateTenantCache={updateTenantCache}
                                reviewCreated={reviewCreated}
                                smsCreated={smsCreated}
                                smsUpdated={smsUpdated}
                                smsDeleted={smsDeleted}
                                emailCreated={emailCreated}
                                emailUpdated={emailUpdated}
                                emailDeleted={emailDeleted}
                                noteCreated={noteCreated}
                                noteUpdated={noteUpdated}
                                noteDeleted={noteDeleted}
                                noteStatusCreated={noteStatusCreated}
                                noteStatusUpdated={noteStatusUpdated}
                                noteStatusDeleted={noteStatusDeleted}
                            />
                        })
                    }
                </>
                :
                <UnderReview />
            }
        </>
    )
}

export default First3090Days;