import React from 'react';
import Grid from '@material-ui/core/Grid';

const NoteCell = (props) => {
    const { content } = props;

    const items = content.split(/\n/);

    return (
        <>
            <Grid container>
                {
                    items.map((item, index) => {
                        return <Grid item key={index} xs={12}>{item}</Grid>
                    })
                }
            </Grid>
        </>
    )
}

export default NoteCell;