import React, { useState, useEffect } from 'react';

import { addItem, updateItem, deleteItem } from "../../../services/firebase"

import PropTypes from 'prop-types';

import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';

import { makeStyles } from '@material-ui/core/styles';

import { updateText } from '../../../utils/TagsUpdate';

const useStyles = makeStyles((theme) => ({
    smsButtons: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        color: theme.palette.common.white,
        backgroundColor: theme.palette.primary.main
    },
    cancelButton: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        color: theme.palette.common.white,
        backgroundColor: theme.palette.success.light
    },
    okButton: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        color: theme.palette.common.white,
        backgroundColor: theme.palette.success.main
    },
    dialog: {
        padding: theme.spacing(2)
    }
}));

const BUTTON_TEXT = {
    NEW: "NEW",
    CANCEL: "CANCEL",
    SAVE: "SAVE",
    UPDATE: "UPDATE",
    DELETE: "DELETE"
}

const SendSmsDialog = (props) => {
    const {
        tenant,
        sms,
        open,
        onCancel,
        onClose,
        smsCreated,
        smsUpdated,
        smsDeleted,
        properties
    } = props;

    const collection = 'sms';

    const t = tenant ? JSON.parse(tenant) : {};

    const [smsId, setSmsId] = useState('');
    const [smsTitle, setSmsTitle] = useState('');
    const [smsTo, setSmsTo] = useState('');
    const [body, setBody] = useState('');
    const [editMode, setEditMode] = useState(false);
    const [titleLength, setTitleLength] = useState(0);
    const [toLength, setToLength] = useState(0);

    const classes = useStyles();

    useEffect(() => {
        const t = tenant ? JSON.parse(tenant) : {};

        if (t.phoneNumbers && t.phoneNumbers.length > 0) {
            setSmsTo(t.phoneNumbers[0].phone);
        }
    }, [tenant]);

    useEffect(() => {
        editMode ? setTitleLength('700px') : setTitleLength('605px');
        editMode ? setToLength('200px') : setToLength('200px');
    }, [editMode]);

    const clear = () => {
        resetSms();
    };

    const cancel = () => {
        onCancel();
    };

    const close = () => {
        const t = JSON.parse(tenant);

        const bodyText = updateText(body, t, properties);
        // console.log(`bodyText: ${bodyText}`);

        onClose({ to: smsTo, body: bodyText });

        if (editMode) {
            setEditMode(false);
        }
        resetSms();
    };

    const smsSelected = (event) => {
        const value = event.target.value;

        const itm = sms.find(item => item.id === value);

        if (itm) {
            setSmsId(value);
            setSmsTitle(itm.title);
            setBody(itm.body);
        }
        else {
            setSmsId('');
            setSmsTitle('');
            setBody('');
        }
    };

    const smsChanged = (event) => {
        setBody(event.target.value);
    }

    const smsToSelected = (event) => {
        const value = event.target.value;

        const itm = t.phoneNumbers.find(item => item.phone === value);

        if (itm) {
            setSmsTo(value);
        }
        else {
            setSmsTo('');
        }
    };

    const newTitleChanged = (event) => {
        setSmsTitle(event.target.value);
    };

    const startNewSms = (event) => {
        setEditMode(!editMode);
        resetSms();
    }

    const saveSms = () => {
        if (editMode) {
            handleAddSms(smsTitle, body);
        }
        else {
            handleUpdateSms(smsId, smsTitle, body);
        }
    }

    const handleAddSms = async (title, body) => {
        try {
            const item = await addItem(collection, {
                title: title,
                body: body,
                createdOn: new Date()
            });

            if (item) {
                setEditMode(false);
                smsCreated(item);
                loadSms(item);
            }
        } catch (error) {
            console.log(`Error adding body: ${error.message}`);
        }
    }

    const handleUpdateSms = async (id, title, body) => {
        try {
            const item = await updateItem(collection, {
                id: id,
                title: title,
                body: body,
                updatedOn: new Date()
            });

            smsUpdated(item);

            if (editMode) {
                setEditMode(false);
            }
        } catch (error) {
            console.log(`Error updating body: ${error.message}`);
        }
    }

    const handleDeleteSms = async (id) => {
        // eslint-disable-next-line no-restricted-globals
        if (!confirm('Are you sure you want to delete this sms?')) {
            return;
        }

        const itm = sms.find(item => item.id === id);
        if (!itm) {
            alert('This SMS not found');
            return;
        }

        try {
            await deleteItem(collection, id);
            smsDeleted(itm);

            resetSms();
        } catch (error) {
            console.log(`Error adding saved sms: ${error.message}`);
        }
    }

    const loadSms = (item) => {
        setSmsId(item.id);
        setSmsTitle(item.title);
    }

    const resetSms = () => {
        setSmsId('');
        setSmsTitle('');
        setBody('');
    }

    return (
        <Dialog open={open} maxWidth="md">
            <Grid container justifyContent="center" alignItems="center" className={classes.dialog} spacing={2}>
                <Grid item xs={12} style={{ width: '600px' }}>
                    <Typography color="primary">{t.tenant}</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Grid container alignItems="flex-end">
                        <Grid item>
                            {!editMode ?
                                <FormControl>
                                    <InputLabel htmlFor="saved-smses">Saved SMS</InputLabel>
                                    <Select
                                        native
                                        onChange={smsSelected}
                                        style={{ minWidth: titleLength }}
                                        value={smsId}
                                    >
                                        <option aria-label="None" value="" />
                                        {
                                            sms.map(itm => {
                                                return <option key={itm.id} value={itm.id}>{itm.title}</option>
                                            })
                                        }
                                    </Select>
                                </FormControl>
                                :
                                <TextField
                                    label="New SMS"
                                    style={{ minWidth: titleLength }}
                                    onChange={newTitleChanged}
                                />
                            }
                        </Grid>
                        <Grid item>
                            <Button
                                variant="contained"
                                className={editMode ? classes.cancelButton : classes.okButton}
                                onClick={startNewSms}
                            >
                                {editMode ? BUTTON_TEXT.CANCEL : BUTTON_TEXT.NEW}
                            </Button>
                            <Button
                                variant="contained"
                                className={classes.smsButtons}
                                disabled={editMode ? (smsTitle.length === 0 || body.length === 0) : !smsId}
                                onClick={() => saveSms()}
                            >
                                {editMode ? BUTTON_TEXT.SAVE : BUTTON_TEXT.UPDATE}
                            </Button>
                            {!editMode &&
                                <Button
                                    variant="contained"
                                    className={classes.smsButtons}
                                    disabled={!smsId}
                                    onClick={() => handleDeleteSms(smsId)}
                                >
                                    {BUTTON_TEXT.DELETE}
                                </Button>
                            }
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <FormControl>
                        <InputLabel htmlFor="smses">To</InputLabel>
                        <Select
                            native
                            onChange={smsToSelected}
                            style={{ minWidth: toLength }}
                            value={smsTo}
                        >
                            {t.phoneNumbers &&
                                t.phoneNumbers.map((item, index) => {
                                    return <option key={index} value={item.phone}>{item.phone}</option>
                                })
                            }
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        size="medium"
                        variant="outlined"
                        multiline={true}
                        rows="8"
                        style={{ width: '100%' }}
                        value={body}
                        onChange={smsChanged}
                        autoFocus
                    />
                </Grid>
                <Grid item xs={12} style={{ textAlign: 'right' }}>
                    <Button
                        variant="contained"
                        className={classes.cancelButton}
                        onClick={() => clear()}
                    >
                        Clear
                    </Button>
                    <Button
                        variant="contained"
                        className={classes.cancelButton}
                        onClick={() => cancel()}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        className={classes.okButton}
                        disabled={smsTo.length === 0 || body.length === 0}
                        onClick={() => close()}
                    >
                        Send Sms
                    </Button>
                </Grid>
            </Grid>
        </Dialog>
    );
}

SendSmsDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    tenant: PropTypes.string.isRequired,
    onCancel: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
};
export default SendSmsDialog;