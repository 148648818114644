import React, { useState, useEffect } from 'react';

import { Link, useHistory } from 'react-router-dom';
import clsx from 'clsx';
import { useAuth } from '../../contexts/AuthContext';

import { makeStyles } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Alert from '@material-ui/lab/Alert';

import { emailValid } from '../../utils/Validator';

const useStyles = makeStyles((theme) => ({
    paper: {
        width: "100%",
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        borderRadius: theme.spacing(1),
    },
    form: {
        padding: theme.spacing(3),
    },
    margin: {
        marginTop: theme.spacing(1),
    },
    textField: {
        width: '25ch',
    },
    input: {
        width: '100%'
    },
    actionButton: {
        width: '100%'
    },
}));

const Login = () => {
    const classes = useStyles();

    const { login, currentUser } = useAuth();
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [valid, setValid] = useState(false);

    const history = useHistory();

    useEffect(() => {
        if (currentUser && !currentUser.welcome) {
            setError('Account under review.');
        }
        else {
            setError('');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentUser]);

    useEffect(() => {
        setValid(email.length > 0 && emailValid(email) && password.length > 0);
    }, [email, password]);

    function emailChanged(event) {
        setEmail(event.target.value);
    };

    function passwordChanged(event) {
        setPassword(event.target.value);
    };

    function showPassword() {
        setPasswordVisible(!passwordVisible);
    };

    function mouseDownPassword(event) {
        event.preventDefault();
    };

    async function submit(e) {
        e.preventDefault();

        try {
            setError('');
            setLoading(true);

            await login(email, password);

            history.push('/');
        } catch (error) {
            setError(error.message);
            console.log(`Failed to sign in: ${error.message}`)
        }

        setLoading(false);
    }

    return (
        <>
            <div className={classes.paper}>
                <Grid container
                    justifyContent="center"
                    className={classes.form}
                    alignItems="center">
                    <Grid item xs={12} style={{ textAlign: 'center' }}>
                        <Typography color="primary" variant="h6" style={{ paddingBottom: '20px' }}>Log In</Typography>
                        {error && <Alert severity="error">{error}</Alert>}
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            label="Email"
                            className={classes.input}
                            onChange={emailChanged}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl className={clsx(classes.margin, classes.textField)} style={{ width: '100%' }}>
                            <InputLabel htmlFor="password">Password</InputLabel>
                            <Input
                                id="password"
                                type={passwordVisible ? 'text' : 'password'}
                                onChange={passwordChanged}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={showPassword}
                                            onMouseDown={mouseDownPassword}
                                        >
                                            {passwordVisible ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                        </FormControl>
                    </Grid>
                    <Grid item style={{ paddingTop: '30px', width: '100%' }} xs={12}>
                        <Button
                            variant="contained"
                            color="primary"
                            className={classes.actionButton}
                            disabled={loading || !valid}
                            onClick={submit}
                        >
                            Login
                        </Button>
                    </Grid>
                    <Grid item style={{ paddingTop: '20px', paddingLeft: '10px', width: '100%', }} xs={12}>
                        <Link to="/forgot-password"><Typography color="primary">Forgot Password?</Typography></Link>
                    </Grid>
                    <Grid item style={{ paddingTop: '10px', paddingLeft: '10px', width: '100%' }} xs={12}>
                        <Grid
                            container
                            spacing={0}
                            direction="row"
                            alignItems="center"
                            justifyContent="center"
                        >
                            <Grid item xs={6}>
                                <Typography>Need an account?</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Link to="/register"><Typography color="primary">Register</Typography></Link>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        </>
    )
}

export default Login;