import { ACTIONS } from "../reducers"

export const updateCurrentTenants = (list) => {
    return (dispatch) => {
        dispatch({
            type: ACTIONS.UPDATE_CURRENT_TENANTS,
            payload: list
        })
    }
}

export const updateCurrentTenant = (item) => {
    return (dispatch) => {
        dispatch({
            type: ACTIONS.UPDATE_CURRENT_TENANT,
            payload: item
        })
    }
}