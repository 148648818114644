import React, { useState, useEffect } from 'react';

import PropTypes from 'prop-types';

import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import Box from '@material-ui/core/Box';

import Rating from '@material-ui/lab/Rating';

import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    DatePicker,
} from '@material-ui/pickers';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    cancelButton: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        color: theme.palette.common.white,
        backgroundColor: theme.palette.success.light
    },
    okButton: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        color: theme.palette.common.white,
        backgroundColor: theme.palette.success.main
    },
    dialog: {
        padding: theme.spacing(2)
    }
}));

const AddReviewDialog = (props) => {
    const {
        tenant,
        sources,
        properties,
        open,
        onCancel,
        onClose,
        resetReview
    } = props;

    const [selectedTenant, setSelectedTenant] = useState(null);
    const [source, setSource] = useState('');
    const [property, setProperty] = useState('');
    const [name, setName] = useState('');
    const [rating, setRating] = useState(0);
    const [review, setReview] = useState('');
    const [reviewDate, setReviewDate] = React.useState(new Date());

    const classes = useStyles();

    useEffect(() => {
        if (tenant && tenant.length > 0) {
            const t = JSON.parse(tenant);

            setSelectedTenant(t);
            setProperty(t.propertyName);
            setName(t.tenant);
        }
        else {
            setSelectedTenant(null);
            setProperty('');
            setName('');
        }
    }, [tenant]);

    useEffect(() => {
        setSelectedTenant(null);
        // setSource('');
        // setProperty('');
        setName('');
        // setReviewDate(new Date());
        setRating(0);
        setReview('');
    }, [resetReview]);

    const cancel = () => {
        onCancel();
    };

    const close = () => {
        onClose(source, property, reviewDate, name, rating, review);
    };

    const sourceChanged = (event) => {
        const value = event.target.value;

        if (sources.includes(value)) {
            setSource(value);
        }
        else {
            setSource('');
        }
    };

    const propertyChanged = (event) => {
        const value = event.target.value;

        if (properties.includes(value)) {
            setProperty(value);
        }
        else {
            setProperty('');
        }
    };

    const reviewDateChanged = (date) => {
        setReviewDate(date);
    };

    const nameChanged = (event) => {
        setName(event.target.value);
    };

    const ratingChanged = (event, newValue) => {
        setRating(newValue);
    }

    const reviewChanged = (event) => {
        setReview(event.target.value);
    }

    return (
        <Dialog open={open}>
            <Grid container justifyContent="center" alignItems="center" className={classes.dialog} spacing={2}>
                <Grid item xs={12} style={{ width: '400px' }}>
                    <Typography color="primary" variant="h6" noWrap>
                        Add Review
                    </Typography>
                </Grid>
                <Grid item xs={5}>
                    <FormControl>
                        <InputLabel htmlFor="saved-sources">Source</InputLabel>
                        <Select
                            native
                            onChange={sourceChanged}
                            style={{ minWidth: '200px' }}
                            value={source}
                        >
                            <option aria-label="None" value="" />
                            {
                                sources.map((source, index) => {
                                    return <option key={index} value={source}>{source}</option>
                                })
                            }
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={7}>
                    <FormControl>
                        <InputLabel htmlFor="saved-sources">Property</InputLabel>
                        <Select
                            native
                            onChange={propertyChanged}
                            style={{ minWidth: '320px' }}
                            value={property}
                            disabled={selectedTenant !== null}
                        >
                            <option aria-label="None" value="" />
                            {
                                properties.map((property, index) => {
                                    return <option key={index} value={property}>{property}</option>
                                })
                            }
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={3}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <DatePicker
                            label="Review date"
                            value={reviewDate}
                            onChange={date => reviewDateChanged(date)}
                            format="MM/dd/yyyy"
                        />
                    </MuiPickersUtilsProvider>
                </Grid>
                <Grid item xs={5}>
                    <TextField
                        label="Name"
                        style={{ minWidth: '280px' }}
                        onChange={nameChanged}
                        value={name}
                        disabled={selectedTenant !== null}
                    />
                </Grid>
                <Grid item xs={4} style={{ textAlign: "center" }}>
                    <Box component="fieldset" borderColor="transparent"
                        style={{ paddingLeft: '50px' }}>
                        <Typography component="legend">Rating: {rating}</Typography>
                        <Rating
                            precision={0.5}
                            name="review-rating"
                            value={rating}
                            onChange={ratingChanged}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        size="medium"
                        variant="outlined"
                        multiline={true}
                        rows="8"
                        style={{ width: '100%' }}
                        onChange={reviewChanged}
                    />
                </Grid>
                <Grid item xs={12} style={{ textAlign: 'right' }}>
                    <Button
                        variant="contained"
                        className={classes.cancelButton}
                        onClick={() => cancel()}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        className={classes.okButton}
                        disabled={source.length === 0 || property.length === 0 || name.length === 0 || rating === 0}
                        onClick={() => close()}
                    >
                        Add Review
                    </Button>
                </Grid>
            </Grid>
        </Dialog>
    );
}

AddReviewDialog.propTypes = {
    tenant: PropTypes.any,
    sources: PropTypes.any.isRequired,
    properties: PropTypes.any.isRequired,
    open: PropTypes.bool.isRequired,
    onCancel: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    resetReview: PropTypes.bool
};
export default AddReviewDialog;