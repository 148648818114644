import { ACTIONS } from "./"

const reducer = (state = {}, action) => {
    switch (action.type) {
        case ACTIONS.UPDATE_SOURCES:
            return action.payload;
        default:
            return state;
    }
}

export default reducer;