import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { bindActionCreators } from 'redux';
import { actionCreators } from "../../store/index"

import { useAuth } from '../../contexts/AuthContext';

import PropertyTable from './property_table/PropertyTable';
import UnderReview from './UnderReview';

const All = () => {
    const { currentUser } = useAuth();
    const [showPage, setShowPage] = useState(false);

    useEffect(() => {
        if (currentUser && currentUser.welcome) {
            setShowPage(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentUser]);

    const dispatch = useDispatch();
    const {
        updateTenant,
        updateCurrentTenant,
        createReview,
        createSms,
        updateSms,
        deleteSms,
        createEmail,
        updateEmail,
        deleteEmail,
        createNote,
        updateNote,
        deleteNote,
        createNoteStatus,
        updateNoteStatus,
        deleteNoteStatus,
    } = bindActionCreators(actionCreators, dispatch);

    const {
        tenants: list,
        sources,
        properties,
        sms,
        emails,
        notes,
        noteStatuses
    } = useSelector((state) => state);

    const updateTenantCache = (item) => {
        updateTenant(item);
        updateCurrentTenant(item);
    }

    const reviewCreated = (item) => {
        createReview(item);
    }

    const smsCreated = (item) => {
        createSms(item);
    }

    const smsUpdated = (item) => {
        updateSms(item);
    }

    const smsDeleted = (item) => {
        deleteSms(item);
    }

    const emailCreated = (item) => {
        createEmail(item);
    }

    const emailUpdated = (item) => {
        updateEmail(item);
    }

    const emailDeleted = (item) => {
        deleteEmail(item);
    }

    const noteCreated = (item) => {
        createNote(item);
    }

    const noteUpdated = (item) => {
        updateNote(item);
    }

    const noteDeleted = (item) => {
        deleteNote(item);
    }

    const noteStatusCreated = (item) => {
        createNoteStatus(item);
    }

    const noteStatusUpdated = (item) => {
        updateNoteStatus(item);
    }

    const noteStatusDeleted = (item) => {
        deleteNoteStatus(item);
    }

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [update, setUpdate] = useState(false);

    useEffect(() => {
    }, [update]);

    const historyUpdated = () => {
        setUpdate(!update);
    }

    const pageChanged = (event, newPage) => {
        setPage(newPage);
    };

    const rowsPerPageChanged = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    return (
        <>
            {showPage
                ?
                < PropertyTable
                    key='All'
                    propertyName='Tenants'
                    showPropertyName={true}
                    list={list}
                    page={page}
                    rowsPerPage={rowsPerPage}
                    pageChanged={pageChanged}
                    rowsPerPageChanged={rowsPerPageChanged}
                    historyUpdated={historyUpdated}
                    updateTenantCache={updateTenantCache}
                    sources={sources}
                    properties={properties}
                    sms={sms}
                    emails={emails}
                    notes={notes}
                    noteStatuses={noteStatuses}
                    reviewCreated={reviewCreated}
                    smsCreated={smsCreated}
                    smsUpdated={smsUpdated}
                    smsDeleted={smsDeleted}
                    emailCreated={emailCreated}
                    emailUpdated={emailUpdated}
                    emailDeleted={emailDeleted}
                    noteCreated={noteCreated}
                    noteUpdated={noteUpdated}
                    noteDeleted={noteDeleted}
                    noteStatusCreated={noteStatusCreated}
                    noteStatusUpdated={noteStatusUpdated}
                    noteStatusDeleted={noteStatusDeleted}
                />
                :
                <UnderReview />
            }
        </>
    );
}

export default All;