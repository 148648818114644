import React, { useState, useEffect } from 'react';

import { deleteItem } from "../../../services/firebase"

import PropTypes from 'prop-types';

import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import MuiTableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';

import Rating from '@material-ui/lab/Rating';

import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import CheckIcon from '@material-ui/icons/Check';

import { makeStyles } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core/styles';

import NoteCell from '../common/NoteCell';

const TableCell = withStyles({
    root: {
        borderBottom: 'none',
        paddingTop: '12px',
        paddingBottom: '12px'
    }
})(MuiTableCell);

const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            borderBottom: 'unset',
        },
    },
    actionButtons: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        color: theme.palette.common.white,
        backgroundColor: theme.palette.success.main
    },
    paper: {
        width: "100%",
    },
    dialog: {
        padding: theme.spacing(2)
    },
    detailsRow: {
        backgroundColor: 'rgba(63, 81, 181, 0.05)'
    },
    detailsRowHeader: {
        borderBottom: '1px solid',
        borderBottomColor: theme.palette.grey.A100
    },
    lineTitle: {
        ...theme.typography.button,
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
    },
    cell: {
        verticalAlign: 'top'
    }
}));

const ReviewTableRow = (props) => {
    const {
        headCells,
        row,
        reviewDeleted
    } = props;
    const collection = 'reviews';

    const [rowOpen, setRowOpen] = useState(false);
    const classes = useStyles();

    useEffect(() => {
    }, [row]);

    const deleteReview = async (review) => {
        // eslint-disable-next-line no-restricted-globals
        if (!confirm('Are you sure you want to delete this record?')) {
            return;
        }

        try {
            await deleteItem(collection, review.id);
            reviewDeleted(review);
        } catch (error) {
            console.log(`Error deleting record: ${error.message}`);
        }
    }

    return (
        <>
            <TableRow className={classes.root}>
                <TableCell>
                    <IconButton aria-label="expand row" size="small" onClick={() => setRowOpen(!rowOpen)}>
                        {rowOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell>{row.reviewDateText}</TableCell>
                <TableCell>{row.source}</TableCell>
                <TableCell>{row.property}</TableCell>
                <TableCell>{row.name}</TableCell>
                <TableCell>
                    <Rating
                        name="rating"
                        precision={0.5}
                        value={row.rating}
                        readOnly
                    />
                </TableCell>
                <TableCell>{row.tenantText === '1' && <CheckIcon />}</TableCell>
            </TableRow>
            <TableRow className={classes.detailsRow}>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={headCells.length + 1}>
                    <Collapse in={rowOpen} timeout="auto" unmountOnExit>
                        <Grid container style={{ paddingTop: 10 }}>
                            <Grid item xs={2}>
                                <Typography variant="h6"
                                    gutterBottom
                                    component="div"
                                    className={classes.lineTitle}
                                >
                                    Details:
                                </Typography>
                            </Grid>
                            <Grid item xs={10} align="right">
                                <Button
                                    variant="contained"
                                    className={classes.actionButtons}
                                    onClick={() => deleteReview(row)}
                                >
                                    Delete Record
                                </Button>
                            </Grid>
                        </Grid>
                        <Box margin={1}>
                            <Table size="small" aria-label="actions">
                                <TableHead className={classes.historyRowHeader}>
                                    <TableRow>
                                        <TableCell>Review</TableCell>
                                        <TableCell style={{ width: '180px' }}>Updated By</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        <TableCell><NoteCell content={row.review}></NoteCell></TableCell>
                                        <TableCell className={classes.cell}>{row.user}</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    );
}

ReviewTableRow.propTypes = {
    headCells: PropTypes.any.isRequired,
    row: PropTypes.shape({
        source: PropTypes.string.isRequired,
        property: PropTypes.string.isRequired,
        name: PropTypes.string,
        rating: PropTypes.number.isRequired,
        review: PropTypes.string,
        tenant: PropTypes.string,
    }).isRequired,
    reviewDeleted: PropTypes.func.isRequired,
};

export default ReviewTableRow;