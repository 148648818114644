import { ACTIONS } from "../reducers"

export const updateReviews = (list) => {
    return (dispatch) => {
        dispatch({
            type: ACTIONS.UPDATE_REVIEWS,
            payload: list
        })
    }
}

export const createReview = (item) => {
    return (dispatch) => {
        dispatch({
            type: ACTIONS.CREATE_REVIEW,
            payload: item
        })
    }
}

export const deleteReview = (item) => {
    return (dispatch) => {
        dispatch({
            type: ACTIONS.DELETE_REVIEW,
            payload: item
        })
    }
}