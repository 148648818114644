import React, { useState, useEffect } from 'react';

import { addItem, updateItem, deleteItem } from "../../../services/firebase"

import PropTypes from 'prop-types';

import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';

import { makeStyles } from '@material-ui/core/styles';

import { updateText } from '../../../utils/TagsUpdate';

const useStyles = makeStyles((theme) => ({
    emailButtons: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        color: theme.palette.common.white,
        backgroundColor: theme.palette.primary.main
    },
    cancelButton: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        color: theme.palette.common.white,
        backgroundColor: theme.palette.success.light
    },
    okButton: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        color: theme.palette.common.white,
        backgroundColor: theme.palette.success.main
    },
    dialog: {
        padding: theme.spacing(2)
    }
}));

const BUTTON_TEXT = {
    NEW: "NEW",
    CANCEL: "CANCEL",
    SAVE: "SAVE",
    UPDATE: "UPDATE",
    DELETE: "DELETE"
}

const SendEmailDialog = (props) => {
    const {
        tenant,
        emails,
        open,
        onCancel,
        onClose,
        emailCreated,
        emailUpdated,
        emailDeleted,
        properties
    } = props;
    const collection = 'emails';

    const t = tenant ? JSON.parse(tenant) : {};

    const [emailId, setEmailId] = useState('');
    const [emailTitle, setEmailTitle] = useState('');
    const [emailTo, setEmailTo] = useState('');
    const [emailCc, setEmailCc] = useState('');
    const [subject, setSubject] = useState('');
    const [body, setBody] = useState('');
    const [editMode, setEditMode] = useState(false);
    const [titleLength, setTitleLength] = useState(0);
    const [toLength, setToLength] = useState(0);

    const classes = useStyles();

    useEffect(() => {
        const t = tenant ? JSON.parse(tenant) : {};
        if (t.emails && t.emails.length > 0) {
            setEmailTo(t.emails[0]);
        }
    }, [tenant]);

    useEffect(() => {
        editMode ? setTitleLength('700px') : setTitleLength('605px');
        editMode ? setToLength('400px') : setToLength('400px');
    }, [editMode]);

    const clear = () => {
        resetEmail();
    };

    const cancel = () => {
        onCancel();
    };

    const close = () => {
        const t = JSON.parse(tenant);

        const bodyText = updateText(body, t, properties);
        const subjectText = updateText(subject, t, properties);
        // console.log(`bodyText: ${bodyText}, subjectText: ${subjectText}`);

        onClose({ to: emailTo, cc: emailCc, subject: subjectText, body: bodyText });

        if (editMode) {
            setEditMode(false);
        }
        resetEmail();
    };

    const emailSelected = (event) => {
        const value = event.target.value;

        const itm = emails.find(item => item.id === value);

        if (itm) {
            setEmailId(value);
            setEmailTitle(itm.title);
            setSubject(itm.subject);
            setBody(itm.body);
        }
        else {
            setEmailId('');
            setEmailTitle('');
            setSubject('');
            setBody('');
        }
    };

    const emailChanged = (event) => {
        setBody(event.target.value);
    }

    const emailToSelected = (event) => {
        const value = event.target.value;

        const itm = t.emails.find(item => item === value);

        if (itm) {
            setEmailTo(value);
        }
        else {
            setEmailTo('');
        }
    };

    const emailCcChanged = (event) => {
        setEmailCc(event.target.value);
    };

    const subjectChanged = (event) => {
        setSubject(event.target.value);
    }

    const newTitleChanged = (event) => {
        setEmailTitle(event.target.value);
    };

    const startNewEmail = (event) => {
        setEditMode(!editMode);
        resetEmail();
    }

    const saveEmail = () => {
        if (editMode) {
            handleAddEmail(emailTitle, subject, body);
        }
        else {
            handleUpdateEmail(emailId, emailTitle, subject, body);
        }
    }

    const handleAddEmail = async (title, subject, body) => {
        try {
            const item = await addItem(collection, {
                title: title,
                subject: subject,
                body: body,
                createdOn: new Date()
            });

            if (item) {
                setEditMode(false);
                emailCreated(item);
                loadEmail(item);
            }
        } catch (error) {
            console.log(`Error adding body: ${error.message}`);
        }
    }

    const handleUpdateEmail = async (id, title, subject, body) => {
        try {
            const item = await updateItem(collection, {
                id: id,
                title: title,
                subject: subject,
                body: body,
                updatedOn: new Date()
            });

            emailUpdated(item);

            if (editMode) {
                setEditMode(false);
            }
        } catch (error) {
            console.log(`Error updating body: ${error.message}`);
        }
    }

    const handleDeleteEmail = async (id) => {
        // eslint-disable-next-line no-restricted-globals
        if (!confirm('Are you sure you want to delete this email?')) {
            return;
        }

        const itm = emails.find(item => item.id === id);
        if (!itm) {
            alert('This Email not found');
            return;
        }

        try {
            await deleteItem(collection, id);
            emailDeleted(itm);

            resetEmail();
        } catch (error) {
            console.log(`Error adding saved email: ${error.message}`);
        }
    }

    const loadEmail = (item) => {
        setEmailId(item.id);
        setEmailTitle(item.title);
        setSubject(item.subject);
        setBody(item.body);
    }

    const resetEmail = () => {
        setEmailId('');
        setEmailTitle('');
        setSubject('');
        setBody('');
    }

    return (
        <Dialog open={open} maxWidth="md">
            <Grid container justifyContent="center" alignItems="center" className={classes.dialog} spacing={2}>
                <Grid item xs={12} style={{ width: '600px' }}>
                    <Typography color="primary">{t.tenant}</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Grid container alignItems="flex-end">
                        <Grid item>
                            {!editMode ?
                                <FormControl>
                                    <InputLabel htmlFor="saved-emails">Saved Emails</InputLabel>
                                    <Select
                                        native
                                        onChange={emailSelected}
                                        style={{ minWidth: titleLength }}
                                        value={emailId}
                                    >
                                        <option aria-label="None" value="" />
                                        {
                                            emails.map(email => {
                                                return <option key={email.id} value={email.id}>{email.title}</option>
                                            })
                                        }
                                    </Select>
                                </FormControl>
                                :
                                <TextField
                                    label="New Email"
                                    style={{ minWidth: titleLength }}
                                    onChange={newTitleChanged}
                                />
                            }
                        </Grid>
                        <Grid item>
                            <Button
                                variant="contained"
                                className={editMode ? classes.cancelButton : classes.okButton}
                                onClick={startNewEmail}
                            >
                                {editMode ? BUTTON_TEXT.CANCEL : BUTTON_TEXT.NEW}
                            </Button>
                            <Button
                                variant="contained"
                                className={classes.emailButtons}
                                disabled={editMode ? (emailTitle.length === 0 || subject.length === 0 || body.length === 0) : !emailId}
                                onClick={() => saveEmail()}
                            >
                                {editMode ? BUTTON_TEXT.SAVE : BUTTON_TEXT.UPDATE}
                            </Button>
                            {!editMode &&
                                <Button
                                    variant="contained"
                                    className={classes.emailButtons}
                                    disabled={!emailId}
                                    onClick={() => handleDeleteEmail(emailId)}
                                >
                                    {BUTTON_TEXT.DELETE}
                                </Button>
                            }
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <FormControl>
                        <InputLabel htmlFor="emails">To</InputLabel>
                        <Select
                            native
                            onChange={emailToSelected}
                            style={{ minWidth: toLength }}
                            value={emailTo}
                        >
                            {t.emails &&
                                t.emails.map(emailTo => {
                                    return <option key={emailTo} value={emailTo}>{emailTo}</option>
                                })
                            }
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        label="cc"
                        style={{ minWidth: '100%' }}
                        value={emailCc}
                        onChange={emailCcChanged}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        label="Subject"
                        style={{ minWidth: '100%' }}
                        value={subject}
                        onChange={subjectChanged}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        size="medium"
                        variant="outlined"
                        multiline={true}
                        rows="15"
                        style={{ width: '100%' }}
                        value={body}
                        onChange={emailChanged}
                        autoFocus
                    />
                </Grid>
                <Grid item xs={12} style={{ textAlign: 'right' }}>
                    <Button
                        variant="contained"
                        className={classes.cancelButton}
                        onClick={() => clear()}
                    >
                        Clear
                    </Button>
                    <Button
                        variant="contained"
                        className={classes.cancelButton}
                        onClick={() => cancel()}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        className={classes.okButton}
                        disabled={emailTo.length === 0 || subject.length === 0 || body.length === 0}
                        onClick={() => close()}
                    >
                        Send Email
                    </Button>
                </Grid>
            </Grid>
        </Dialog>
    );
}

SendEmailDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    tenant: PropTypes.string.isRequired,
    onCancel: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
};
export default SendEmailDialog;