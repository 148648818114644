import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { bindActionCreators } from 'redux';
import { actionCreators } from "../../store/index"

import { useAuth } from '../../contexts/AuthContext';

import PropTypes from 'prop-types';

import ReviewTable from './reviews/ReviewTable';
import UnderReview from './UnderReview';

const Reviews = () => {
    const { currentUser } = useAuth();
    const [showPage, setShowPage] = useState(false);

    useEffect(() => {
        if (currentUser && currentUser.welcome) {
            setShowPage(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentUser]);

    const dispatch = useDispatch();
    const {
        createReview,
        deleteReview
    } = bindActionCreators(actionCreators, dispatch);

    const { reviews: list } = useSelector((state) => state);

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const { sources, properties } = useSelector((state) => state);

    let propertyNames = [];
    if (properties && properties instanceof Array === true) {
        propertyNames = properties
            .filter(item => item.active === true)
            .map(item => item.name).sort();
    }

    const pageChanged = (event, newPage) => {
        setPage(newPage);
    };

    const rowsPerPageChanged = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const reviewCreated = (review) => {
        createReview(review);
    }

    const reviewDeleted = (review) => {
        deleteReview(review);
    }

    return (
        <>
            {showPage
                ?
                <ReviewTable
                    list={list}
                    page={page}
                    rowsPerPage={rowsPerPage}
                    pageChanged={pageChanged}
                    rowsPerPageChanged={rowsPerPageChanged}
                    sources={sources}
                    properties={propertyNames}
                    reviewCreated={reviewCreated}
                    reviewDeleted={reviewDeleted}
                />
                :
                <UnderReview />
            }
        </>
    )
}

Reviews.propTypes = {
    list: PropTypes.any,
    tenants: PropTypes.any,
};

export default Reviews;