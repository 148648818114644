import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import { makeStyles } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Alert from '@material-ui/lab/Alert';

import { emailValid } from '../../utils/Validator';

const useStyles = makeStyles((theme) => ({
    paper: {
        width: "100%",
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        borderRadius: theme.spacing(1),
    },
    form: {
        padding: theme.spacing(3),
    },
    margin: {
        marginTop: theme.spacing(1),
    },
    textField: {
        width: '25ch',
    },
    input: {
        width: '100%'
    },
    actionButton: {
        width: '100%'
    },
}));

const ForgotPassword = () => {
    const classes = useStyles();

    const { resetPassword } = useAuth();
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState('');
    const [valid, setValid] = useState(false);
    const [message, setMessage] = useState(false);

    useEffect(() => {
        setValid(email.length > 0 && emailValid(email));
    }, [email]);

    function emailChanged(event) {
        setEmail(event.target.value);
    };

    async function submit(e) {
        e.preventDefault();

        try {
            setMessage('');
            setError('');
            setLoading(true);

            await resetPassword(email);
            setMessage('Password reset instructions were sent to your email')
        } catch (error) {
            setError(error.message);
            console.log(`Failed to reset password: ${error.message}`)
        }

        setLoading(false);
    }

    return (
        <>
            <div className={classes.paper}>
                <Grid container
                    justifyContent="center"
                    className={classes.form}
                    alignItems="center">
                    <Grid item xs={12} style={{ textAlign: 'center' }}>
                        <Typography color="primary" variant="h6" style={{ paddingBottom: '20px' }}>Password Reset</Typography>
                        {error && <Alert severity="error">{error}</Alert>}
                        {message && <Alert severity="info">{message}</Alert>}
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            label="Email"
                            className={classes.input}
                            onChange={emailChanged}
                        />
                    </Grid>
                    <Grid item style={{ paddingTop: '30px', width: '100%' }} xs={12}>
                        <Button
                            variant="contained"
                            color="primary"
                            className={classes.actionButton}
                            disabled={loading || !valid}
                            onClick={submit}
                        >
                            Reset Password
                        </Button>
                    </Grid>
                    <Grid item style={{ paddingTop: '20px', paddingLeft: '10px', width: '100%', }} xs={12}>
                        <Link to="/login"><Typography color="primary">Log In</Typography></Link>
                    </Grid>
                    <Grid item style={{ paddingTop: '10px', paddingLeft: '10px', width: '100%' }} xs={12}>
                        <Grid
                            container
                            spacing={0}
                            direction="row"
                            alignItems="center"
                            justifyContent="center"
                        >
                            <Grid item xs={6}>
                                <Typography>Need an account?</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Link to="/register"><Typography color="primary">Register</Typography></Link>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        </>
    )
}

export default ForgotPassword;