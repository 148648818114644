import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";
import { bindActionCreators } from 'redux';
import { actionCreators } from "../../store/index"

import { useAuth } from '../../contexts/AuthContext';

import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    tabs: {
        boxShadow: "0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 0%)",
        display: "none",
        [theme.breakpoints.up('md')]: {
            display: "block"
        },
        marginBottom: theme.spacing(1)
    },
}));


const Navigation = () => {
    const classes = useStyles();
    const { currentUser } = useAuth();

    const dispatch = useDispatch();
    const { updateUI } = bindActionCreators(actionCreators, dispatch);

    const { ui } = useSelector((state) => state);

    const history = useHistory();

    const [selectedTab, setSelectedTab] = useState(ui.selectedTab === undefined ? 0 : ui.selectedTab);

    const tabs = [
        {
            id: 0,
            label: "Dashboard",
            url: "/"
        },
        {
            id: 1,
            label: "All",
            url: "/all"
        },
        {
            id: 2,
            label: "Current",
            url: "/current"
        },
        {
            id: 3,
            label: "0-30 days",
            url: "/first-30-days"
        },
        {
            id: 4,
            label: "30 - 90 days",
            url: "/first-30-90-days"
        },
        {
            id: 5,
            label: "Over 90 days",
            url: "/over-90-days"
        },
        {
            id: 6,
            label: "Notice",
            url: "/notice"
        },
        {
            id: 7,
            label: "Future",
            url: "/future"
        },
        {
            id: 8,
            label: "Reviews",
            url: "/reviews"
        },
        {
            id: 9,
            label: "Settings",
            url: "/settings"
        }
    ];

    useEffect(() => {
        const tab = tabs.find(item => item.url === history.location.pathname);

        if (tab) {
            setSelectedTab(tab.id);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const navigationTabChanged = (event, newValue) => {
        setSelectedTab(newValue);
        updateUI({ ...ui, selectedTab: newValue });

        const tab = tabs.find(item => item.id === newValue);

        if (tab) {
            history.push(tab.url);
        }
    };

    function TabPanel(props) {
        const { children, value, index, ...other } = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`navigation-tabpanel-${index}`}
                aria-labelledby={`navigation-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <Box p={3}>
                        <Typography>{children}</Typography>
                    </Box>
                )}
            </div>
        );
    }

    TabPanel.propTypes = {
        children: PropTypes.node,
        index: PropTypes.any.isRequired,
        value: PropTypes.any.isRequired,
    };

    function tabProps(index) {
        return {
            id: `navigation-tab-${index}`,
            'aria-controls': `navigation-tabpanel-${index}`,
        };
    }

    return (
        <>
            {currentUser &&
                <div className={classes.root}>
                    <AppBar position="sticky" className={classes.tabs}>
                        <Tabs value={selectedTab} onChange={navigationTabChanged} aria-label="navigation">
                            {
                                tabs.map(tab => {
                                    return <Tab label={tab.label} key={tab.id} {...tabProps(tab.id)} />
                                })
                            }
                        </Tabs>
                    </AppBar>
                </div>
            }
        </>
    );
}

export default Navigation;
