import React, { useEffect, useState } from 'react';

import { useHistory } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';

import { useSelector, useDispatch } from "react-redux";
import { bindActionCreators } from 'redux';
import { actionCreators } from "../../store/index"

import { getTenants, saveTenants, getHistory, getReviews, getItems, addItem } from "../../services/firebase"

import { makeStyles } from '@material-ui/core/styles';

import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import Rating from '@material-ui/lab/Rating';

import UnderReview from './UnderReview';

import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer,
    ReferenceLine
} from 'recharts';

const parse = require('csv-parse');
const moment = require('moment');

const TENANT_STATUS = {
    CURRENT: "Current",
    NOTICE: "Notice",
    FUTURE: "Future",
    PAST: "Past"
}

function addDays(date, days) {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
}

const useStyles = makeStyles((theme) => ({
    list: {
        width: 250,
    },
    tenantsUploadInput: {
        display: 'none',
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    header: {
        backgroundColor: theme.palette.primary.light,
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
    lineTitle: {
        ...theme.typography.button,
        backgroundColor: theme.palette.background.paper,
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
    },
    section: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1)
    },
    table: {
        padding: theme.spacing(1),
        border: 'solid 1px',
        borderRadius: theme.spacing(1),
        borderColor: theme.palette.primary.light
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    success: {
        backgroundColor: theme.palette.success.main,
        color: theme.palette.common.white
    },
    hr: {
        height: '1px',
        width: '98%',
        background: theme.palette.primary.light,
        marginLeft: theme.spacing(1),
        marginBottom: theme.spacing(1),
    }
}));

const Dashboard = (props) => {
    const classes = useStyles();
    const history = useHistory();
    const { currentUser } = useAuth();

    const dispatch = useDispatch();
    const {
        updateTenants,
        updateCurrentTenants,
        updateNoticeTenants,
        updateFutureTenants,
        updateFirst30daysTenants,
        updateFirst3090daysTenants,
        updateOver90daysTenants,
        updateReviews,
        updateSources,
        updateProperties,
        updateSmses,
        updateEmails,
        updateNotes,
        updateNoteStatuses
    } = bindActionCreators(actionCreators, dispatch);

    const [updated, setUpdated] = useState(false);
    const [averagesByMonth, setAveragesByMonth] = useState([]);
    const [averagesByMonthFiltered, setAveragesByMonthFiltered] = useState([]);

    const getTenantRecord = (headers, record) => {
        if (record.length === headers && record[6].length > 0) {
            let moveIn = null;
            let field = record[5];

            if (field !== undefined && field.length > 0) {
                moveIn = new Date(field);
            }

            let moveOut = null;
            field = record[7];

            if (field !== undefined && field.length > 0) {
                moveOut = new Date(field);
            }

            let phoneNumbers = [];
            field = record[3];

            if (field !== undefined && field.length > 0) {
                let phoneNumbersArr = field.split(',');

                phoneNumbers = phoneNumbersArr.map(item => {
                    const itemArr = item.split(':');

                    if (itemArr.length === 2) {
                        return {
                            type: itemArr[0].trim(),
                            phone: itemArr[1].trim()
                        }
                    }
                    return null;
                })
            }

            return {
                unit: record[0],
                tenant: record[1],
                status: record[2],
                phoneNumbers: phoneNumbers,
                emails: record[4].split(','),
                moveIn: moveIn,
                propertyName: record[6],
                moveOut: moveOut,
                show: true
            };
        }

        return null;
    }

    const getTenantsData = async () => {
        const history = await getHistory();
        let tenants = await getTenants();

        let user = JSON.parse(localStorage.getItem('user'));

        if (user instanceof Array === true && user.length > 0) {
            user = user[0];

            tenants = tenants.filter(item => user.properties.includes(item.propertyName));
        }

        if (history) {
            history.forEach(history => {
                const tenant = tenants.find(tenant => tenant.id === history.tenant);
                if (tenant) {
                    tenant.history.push(history);
                }
            })
        }

        if (tenants) {
            tenants.forEach(tenant => {
                if (tenant.emails) {
                    tenant.emails = tenant.emails.filter(item => item.length > 0);
                }

                if (tenant.history.length > 0) {
                    tenant.history = tenant.history.sort((a, b) => b.createdOn - a.createdOn)
                    tenant.lastAction = `${tenant.history[0].action} on ${moment(tenant.history[0].createdOn).format('L')}`;
                }
            })
        }

        await loadTenants(tenants);
    }

    const loadTenants = async (list) => {
        // console.log('load list', list);
        if (list === undefined || list === null) {
            return;
        }

        updateTenants(list);
        // console.log('tenants', list);

        const currentTenants = list.filter(item => item.status === TENANT_STATUS.CURRENT);
        // console.log('currentTenants', currentTenants);
        updateCurrentTenants(currentTenants);

        const noticeTenants = list.filter(item => item.status === TENANT_STATUS.NOTICE);
        // console.log('noticeTenants', noticeTenants);
        updateNoticeTenants(noticeTenants);

        const today = new Date();

        const first30days = list.filter(item => {
            if (item.status === TENANT_STATUS.PAST && item.moveOut) {
                const last = addDays(item.moveOut, 30);
                return today >= item.moveOut && today <= last;
            }
            return null;
        })
        // console.log('first30days', first30days);
        updateFirst30daysTenants(first30days);

        const first3090days = list.filter(item => {
            if (item.status === TENANT_STATUS.PAST && item.moveOut) {
                const first = addDays(item.moveOut, 30);
                const last = addDays(item.moveOut, 90);
                return today > first && today < last;
            }
            return null;
        })
        // console.log('first3090days', first3090days);
        updateFirst3090daysTenants(first3090days);

        const over90days = list.filter(item => {
            if (item.status === TENANT_STATUS.PAST && item.moveOut) {
                const first = addDays(item.moveOut, 90);
                return today > first;
            }
            return null;
        })
        // console.log('over90days', over90days);
        updateOver90daysTenants(over90days);

        const futureTenants = list.filter(item => item.status === TENANT_STATUS.FUTURE);
        // console.log('futureTenants', futureTenants);
        updateFutureTenants(futureTenants);
    }

    const getTenantSupportData = async () => {
        const sms = await getItems('sms');

        updateSmses(sms);
        // console.log('sms', sms);

        const emails = await getItems('emails');

        updateEmails(emails);
        // console.log('emails', emails);

        const notes = await getItems('notes');

        updateNotes(notes);
        // console.log('notes', notes);

        const noteStatuses = await getItems('notes-for-status-change');

        updateNoteStatuses(noteStatuses);
        // console.log('noteStatuses', noteStatuses);
    }

    const getReviewsData = async () => {
        const list = await getReviews();

        updateReviews(list);
        // console.log('reviews', list);

        const sources = await getItems('sources');
        if (sources instanceof Array) {
            updateSources([...new Set(sources.map(item => item.title))].sort());
        }
    }

    const getProperties = async () => {
        let properties = await getItems('properties');
        // console.log('properties', properties);

        let user = JSON.parse(localStorage.getItem('user'));

        if (user instanceof Array === true && user.length > 0) {
            user = user[0];

            properties = properties.filter(item => user.properties.includes(item.name));
        }

        updateProperties(properties.sort());
    }

    const getData = async () => {
        setBackdropOpen(true);

        await getProperties();
        await getTenantsData();
        await getTenantSupportData();
        await getReviewsData();

        setBackdropOpen(false);
        setUpdated(!updated);
    }

    const createStatistics = () => {
        createTenantsStatistics();
        createReviewsStatistics();

        createChartDateRanges();

        const reviewsChartData = getReviewsChart(reviews);
        setAveragesByMonth(reviewsChartData);
    }

    const createChartDateRanges = () => {
        const date = new Date();
        const monthFirstDay = new Date(date.getFullYear(), date.getMonth(), 1);

        let quarterFirstDay;
        if (date.getMonth() < 3) {
            quarterFirstDay = new Date(date.getFullYear(), 0, 1);
        }
        else if (date.getMonth() < 6) {
            quarterFirstDay = new Date(date.getFullYear(), 3, 1);
        }
        else if (date.getMonth() < 9) {
            quarterFirstDay = new Date(date.getFullYear(), 6, 1);
        }
        else {
            quarterFirstDay = new Date(date.getFullYear(), 9, 1);
        }

        const yearFirstDay = new Date(date.getFullYear(), 0, 1);
        const lastThreeYearsFirstDay = new Date(date.getFullYear() - 3, 0, 1);
        const lastFiveYearsFirstDay = new Date(date.getFullYear() - 5, 0, 1);
        const lastSevenYearsFirstDay = new Date(date.getFullYear() - 7, 0, 1);
        const lastTenYearsFirstDay = new Date(date.getFullYear() - 10, 0, 1);

        const list = [
            {
                id: 1,
                label: 'All'
            },
            {
                id: 2,
                label: 'This month',
                dateFrom: monthFirstDay,
                dateTo: date
            },
            {
                id: 3,
                label: 'This quarter',
                dateFrom: quarterFirstDay,
                dateTo: date
            },
            {
                id: 4,
                label: 'This year',
                dateFrom: yearFirstDay,
                dateTo: date
            },
            {
                id: 5,
                label: 'Last 3 years',
                dateFrom: lastThreeYearsFirstDay,
                dateTo: date
            },
            {
                id: 6,
                label: 'Last 5 years',
                dateFrom: lastFiveYearsFirstDay,
                dateTo: date
            },
            {
                id: 7,
                label: 'Last 7 years',
                dateFrom: lastSevenYearsFirstDay,
                dateTo: date
            },
            {
                id: 8,
                label: 'Last 10 years',
                dateFrom: lastTenYearsFirstDay,
                dateTo: date
            }
        ];

        setChartDateRanges(list);
    }

    const refreshData = () => {
        history.push('/');

        localStorage.removeItem("state");

        getData();
    }

    const getDailyRefresh = () => {
        const refresh = localStorage.getItem("refresh");

        if (!refresh) {
            localStorage.setItem("refresh", new Date());
            refreshData();
        }
        else {
            if (addDays(refresh, 1) < new Date()) {
                refreshData();
                localStorage.setItem("refresh", new Date());
            }
        }
    }

    useEffect(() => {
        if (currentUser && currentUser.welcome) {
            getDailyRefresh();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentUser]);

    useEffect(() => {
        createStatistics();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updated]);

    useEffect(() => {
        updateChartData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [averagesByMonth]);

    const createTenantsStatistics = () => {
        setDatabaseStatisticsTenants([
            {
                id: 1,
                title1: 'Total Tenants',
                value1: tenants ? tenants.length : '',
                title2: 'Hidden',
                value2: tenants ? getHiddenTenantsCount(tenants) : '',
            },
            {
                id: 2,
                spacer: true
            },
            {
                id: 3,
                title1: 'Current',
                value1: currentTenants ? currentTenants.length : '',
                title2: 'Hidden',
                value2: currentTenants ? getHiddenTenantsCount(currentTenants) : '',
            },
            {
                id: 4,
                title1: 'First 30 days',
                value1: first30Tenants ? first30Tenants.length : '',
                title2: 'Hidden',
                value2: first30Tenants ? getHiddenTenantsCount(first30Tenants) : '',
            },
            {
                id: 5,
                title1: '30 - 90 days',
                value1: first3090Tenants ? first3090Tenants.length : '',
                title2: 'Hidden',
                value2: first3090Tenants ? getHiddenTenantsCount(first3090Tenants) : '',
            },
            {
                id: 6,
                title1: 'Over 90 days',
                value1: over90Tenants ? over90Tenants.length : '',
                title2: 'Hidden',
                value2: over90Tenants ? getHiddenTenantsCount(over90Tenants) : '',
            },
            {
                id: 7,
                title1: 'Notice',
                value1: noticeTenants ? noticeTenants.length : '',
                title2: 'Hidden',
                value2: noticeTenants ? getHiddenTenantsCount(noticeTenants) : '',
            },
            {
                id: 8,
                title1: 'Future',
                value1: futureTenants ? futureTenants.length : '',
                title2: 'Hidden',
                value2: futureTenants ? getHiddenTenantsCount(futureTenants) : '',
            },
        ]);
    }

    const createReviewsStatistics = () => {
        if (sources instanceof Array === false ||
            sources === undefined || sources === null ||
            reviews instanceof Array === false ||
            reviews === undefined || reviews === null) {
            return;
        }

        const reviewsBySources = sources.map(source => {
            const list = reviews.filter(review => review.source === source);
            return {
                source: source,
                reviews: list
            }
        })

        const reviewsRatings = reviews.map(review => review.rating);

        const list = [
            {
                id: 1,
                title1: 'Total Reviews',
                value1: reviews ? reviews.length : '',
                title2: 'Average',
                value2: reviewsRatings && reviewsRatings.length > 0 ? (sum(reviewsRatings) / reviewsRatings.length).toFixed(2) : ''
            }];

        list.push({ id: 2, spacer: true });

        let i = 3;
        reviewsBySources.forEach(item => {
            const ratings = item.reviews.map(review => review.rating);

            list.push(
                {
                    id: i,
                    title1: item.source,
                    value1: item.reviews ? item.reviews.length : '',
                    title2: 'Average',
                    value2: ratings && ratings.length > 0 ? (sum(ratings) / ratings.length).toFixed(2) : ''
                }
            )
            i += 1;
        })

        list.push({ id: i, spacer: true });
        i += 1;

        let propertyNames = [];
        if (properties) {
            propertyNames = properties
                .filter(item => item.active === true)
                .map(item => item.name).sort();
        }

        const reviewsBypPoperties = propertyNames.map(property => {
            const list = reviews.filter(review => review.property === property);
            return {
                property: property,
                reviews: list
            }
        })

        reviewsBypPoperties.forEach(item => {
            const ratings = item.reviews.map(review => review.rating);

            list.push(
                {
                    id: i,
                    title1: item.property,
                    value1: item.reviews ? item.reviews.length : '',
                    title2: 'Average',
                    value2: ratings && ratings.length > 0 ? (sum(ratings) / ratings.length).toFixed(2) : ''
                }
            )
            i += 1;
        })

        setDatabaseStatisticsReviews(list);
    }

    // Charts

    // eslint-disable-next-line no-unused-vars
    const [reviewsFromDate, setReviewsFromDate] = useState(2002);

    const getReviewsChart = (list) => {
        // console.log('getReviewsChart', list);
        if (list instanceof Array === false ||
            list === undefined ||
            list === null) {
            return [];
        }

        const ratingsList = list.map(item => {
            return {
                date: item.reviewDate,
                rating: item.rating
            }
        });
        // console.log('ratingsList', ratingsList);

        const dates = getChartDates();
        // console.log('dates', dates);

        const data = getChartData(dates, ratingsList);
        // console.log('data', data);

        return data;
    }

    const getChartDates = () => {
        const dates = [];

        const yearEnd = (new Date()).getFullYear();
        const monthEnd = (new Date()).getMonth() + 1;

        for (let year = reviewsFromDate; year <= yearEnd; year++) {
            for (let month = 1; month <= 12; month++) {
                if (year === yearEnd && month === monthEnd + 2) {
                    break;
                }

                dates.push(new Date(`${month}/1/${year}`));
            }
        }

        return dates;
    }

    const getChartData = (dates, ratingsList) => {
        const ratings = [];
        let averagePrevMonth = 0;
        const runningAverageArr = [];
        let runningCount = 0;

        for (let i = 0; i < dates.length - 1; i++) {
            const monthRatings = ratingsList.filter(item =>
                new Date(item.date).getTime() >= new Date(dates[i]).getTime() &&
                new Date(item.date).getTime() < new Date(dates[i + 1]).getTime());

            if (monthRatings) {
                const ratingsArr = monthRatings.map(item => item.rating);
                const average = ratingsArr && ratingsArr.length > 0 ? (sum(ratingsArr) / ratingsArr.length) : 0;
                // console.log(`date: ${dates[i]}, ratings: ${ratings}, average: ${average}`);

                runningAverageArr.push(...ratingsArr);
                runningCount += monthRatings.length;

                let rating = {
                    date: dates[i],
                    dateText: moment(dates[i]).format('MMM,YY')
                };

                if (average === 0) {
                    rating.Rating = averagePrevMonth;
                }
                else {
                    averagePrevMonth = average;
                    rating.Rating = average;
                }

                let runningAverage = runningAverageArr && runningAverageArr.length > 0 ? (sum(runningAverageArr) / runningAverageArr.length) : 0;
                runningAverage = runningAverage.toFixed(2);

                rating.Average = runningAverage;
                rating.Count = runningCount;
                ratings.push(rating);
            }
        }

        return ratings;
    }

    const updateChartData = (dateFrom, dateTo) => {
        if (dateFrom === undefined) {
            dateFrom = new Date(reviewsFromDate);
        }

        if (dateTo === undefined) {
            dateTo = new Date();
        }

        const filtered = averagesByMonth.filter(item => item.date >= dateFrom && item.date < dateTo);
        setAveragesByMonthFiltered(filtered);
    }

    const [chartDateRanges, setChartDateRanges] = useState([]);
    const [, setChartDateRangeLabel] = useState('All');
    const [chartDateRangeId, setChartDateRangeId] = useState(1);

    const chartDateRangeChanged = (event) => {
        const value = parseInt(event.target.value);

        const found = chartDateRanges.find(item => item.id === value);

        if (found) {
            setChartDateRangeId(found.id);
            setChartDateRangeLabel(found.label);

            updateChartData(found.dateFrom, found.dateTo);
        }
        else {
            setChartDateRangeId('');
            setChartDateRangeLabel('');
            updateChartData();
        }
    };

    const [databaseStatisticsTenants, setDatabaseStatisticsTenants] = useState([]);
    const [databaseStatisticsReviews, setDatabaseStatisticsReviews] = useState([]);

    const {
        tenants,
        currentTenants,
        noticeTenants,
        first30Tenants,
        first3090Tenants,
        over90Tenants,
        futureTenants,
        reviews,
        sources,
        properties
    } = useSelector((state) => state);

    const loadFile = async (e) => {
        e.preventDefault();

        const reader = new FileReader();

        reader.onload = async (e) => {
            const text = (e.target.result);
            const lines = text.split('\n');

            const list = [];
            let headers = 0;

            if (lines.length > 0) {
                for (let i = 0; i < lines.length; i++) {
                    const parser = parse({
                        delimiter: ','
                    });

                    // eslint-disable-next-line no-loop-func
                    parser.on('readable', () => {
                        let record;
                        // eslint-disable-next-line no-cond-assign
                        while (record = parser.read()) {
                            if (i === 0) {
                                headers = record.length;
                            }
                            else {
                                const itm = getTenantRecord(headers, record);
                                if (itm !== null) {
                                    list.push(itm);
                                }
                            }
                        }
                    });

                    parser.on('error', (err) => {
                        console.error(err.message);
                    });

                    parser.write(lines[i]);
                    parser.end();
                }
            }
            // console.log('list', list);

            let listToLoad = [];

            const newTenants = getNewTenants(list);
            // console.log('newTenants', newTenants);

            if (newTenants && newTenants.length > 0) {
                listToLoad = [...listToLoad, ...newTenants];
            }

            const updatedTenants = getUpdatedTenants(list, (newTenants && newTenants.length > 0));
            // console.log('updatedTenants', updatedTenants);

            if (updatedTenants && updatedTenants.length > 0) {
                listToLoad = [...listToLoad, ...updatedTenants];
            }

            // console.log('listToLoad', listToLoad)

            if (listToLoad.length > 0) {
                alert(`${newTenants.length} new and ${updatedTenants.length} updated tenants will be loaded.`);
                setBackdropOpen(true);

                const batch = await getNextBatch();

                listToLoad.forEach(item => item.batch = batch);
                await saveTenants(listToLoad);

                alert('Load is completed. Refreshing data...')

                getTenantsData();
                getData();
            }
            else {
                alert('No records found to load');
            }
        };

        reader.readAsText(e.target.files[0]);
    }

    const getNewTenants = (list) => {
        if (list === undefined || list === null || list.length === 0) {
            return [];
        }

        if (tenants instanceof Array === false || tenants.length === 0) {
            return list;
        }

        return list.filter(item => {
            const arr = tenants.filter(itm => {
                return itm.propertyName === item.propertyName &&
                    itm.unit === item.unit &&
                    itm.tenant === item.tenant;
            });

            if (arr.length === 0) {
                return item;
            }
            return null;
        });
    }

    const getUpdatedTenants = (list, newTenants) => {
        if (list instanceof Array === false || list.length === 0) {
            return [];
        }

        if ((tenants instanceof Array === false || tenants.length === 0) && !newTenants) {
            return list;
        }

        const lst = tenants.filter(item => {
            const arr = list.filter(itm => {
                return (itm.propertyName === item.propertyName &&
                    itm.unit === item.unit &&
                    itm.tenant === item.tenant) &&
                    (itm.status !== item.status ||
                        new Date(itm.moveOut).getTime() / 1000 !== new Date(item.moveOut).getTime() / 1000)
            });

            if (arr.length > 0) {
                return item;
            }
            return null;
        });

        if (lst.length > 0) {
            updateUpdatedTenants(lst, list);
        }

        return lst;
    }

    const updateUpdatedTenants = (list, loadList) => {
        for (let i = 0; i < list.length; i++) {
            const item = list[i];

            const found = loadList.find(itm => itm.propertyName === item.propertyName &&
                itm.unit === item.unit &&
                itm.tenant === item.tenant);

            if (found) {
                item.emails = found.emails;
                item.moveIn = found.moveIn;
                item.moveOut = found.moveOut;
                item.phoneNumbers = found.phoneNumbers;
                item.status = found.status;
            }

            const props = ['emailsSearch', 'history', 'lastAction', 'moveInText', 'moveOutText', 'phoneNumbersSearch'];

            props.forEach(prop => {
                if (item[prop]) {
                    delete item[prop];
                }
            })
        }
    }

    const getNextBatch = async () => {
        let batch = 0;

        const batches = await getItems('batches');

        if (batches.length > 0) {
            const batchNumbers = batches.map(item => item.batch);
            batch = Math.max.apply(Math, batchNumbers);
            batch += 1;
        }

        const nextBatch = await addItem('batches', {
            batch: batch,
            user: getCurrentUser(),
            createdOn: new Date()
        })

        if (nextBatch) {
            return nextBatch.batch;
        }
        else {
            return batch;
        }
    }

    const getCurrentUser = () => {
        let user = localStorage.getItem("user");
        if (user) {
            user = JSON.parse(user);
            if (user) {
                return user.email;
            }
        }
        return null;
    }

    const getHiddenTenantsCount = (list) => {
        if (list instanceof Array) {
            return list.filter(item => item.show === false).length;
        }
        else {
            return 0;
        }
    }

    const ReviewsTable = (props) => {
        const { items } = props;

        return (
            <Grid container className={classes.table}>
                {
                    items.map((item, index) => (
                        <ReviewsTableRow key={item.id} item={item} />
                    ))
                }
            </Grid>
        )
    }

    const ReviewsTableRow = (props) => {
        const { item } = props;

        return (
            <>
                {
                    item.spacer
                        ?
                        <Grid item xs={12}>
                            <div className={classes.hr}>&nbsp;</div>
                        </Grid>
                        :
                        <>
                            <Grid item xs={6}>
                                <div className={classes.lineTitle}>{item.title1}:</div>
                            </Grid>
                            <Grid item xs={1}>
                                <div className={classes.lineTitle}>{item.value1 > 0 ? item.value1 : ''}</div>
                            </Grid>
                            <Grid item xs={5}>
                                <Grid container>
                                    <Grid item xs={9} style={{ textAlign: "right" }}>
                                        <Rating
                                            precision={0.5}
                                            name="review-rating"
                                            value={parseFloat(item.value2)}
                                        />
                                    </Grid>
                                    <Grid item xs={3} style={{ textAlign: "center" }}>
                                        <Typography component="legend" color="textSecondary">{item.value2}</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </>
                }
            </>
        )
    }

    const FourLinesTable = (props) => {
        const { items } = props;

        return (
            <Grid container className={classes.table}>
                {
                    items.map((item, index) => (
                        <FourLinesTableRow key={item.id} item={item} />
                    ))
                }
            </Grid>
        )
    }

    const FourLinesTableRow = (props) => {
        const { item } = props;

        return (
            <>
                {
                    item.spacer
                        ?
                        <Grid item xs={12}>
                            <div className={classes.hr}>&nbsp;</div>
                        </Grid>
                        :
                        <>
                            <Grid item xs={6}>
                                <div className={classes.lineTitle}>{item.title1}:</div>
                            </Grid>
                            <Grid item xs={2}>
                                <div className={classes.lineTitle}>{item.value1 > 0 ? item.value1 : ''}</div>
                            </Grid>
                            <Grid item xs={3} style={{ textAlign: "right" }} >
                                <div className={classes.lineTitle}>{item.title2}:</div>
                            </Grid>
                            <Grid item xs={1}>
                                <div className={classes.lineTitle}>{item.value2 > 0 ? item.value2 : ''}</div>
                            </Grid>
                        </>
                }
            </>
        )
    }

    const sum = (arr) => {
        const reducer = (sum, val) => sum + parseFloat(val);
        const initialValue = 0;
        return arr.reduce(reducer, initialValue);
    }

    // Backdrop

    const [backdropOpen, setBackdropOpen] = useState(false);

    return (
        <>
            {currentUser && currentUser.welcome
                ?
                <>
                    <Backdrop className={classes.backdrop} open={backdropOpen}>
                        <CircularProgress color="inherit" />
                    </Backdrop>

                    <Container maxWidth="lg">
                        <Grid container>
                            <Grid item xs={12} className={classes.section}>
                                <Grid container spacing={2}>
                                    <Grid item xs={7}>
                                        <ReviewsTable items={databaseStatisticsReviews} />
                                    </Grid>
                                    <Grid item xs={5}>
                                        <FourLinesTable items={databaseStatisticsTenants} />

                                        <Box style={{ paddingTop: '20px' }}>
                                            <input
                                                accept="text/*"
                                                className={classes.tenantsUploadInput}
                                                id="tenants-button-file"
                                                multiple={false}
                                                type="file"
                                                onChange={(e) => loadFile(e)}
                                            />
                                            <label htmlFor="tenants-button-file">
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    component="span"
                                                >
                                                    Upload Tenant Directory
                                                </Button>
                                            </label>
                                        </Box>
                                        <Box style={{ paddingTop: '20px' }}>
                                            <Button
                                                variant="contained"
                                                component="span"
                                                className={classes.success}
                                                onClick={refreshData}
                                            >
                                                Refresh from Server
                                            </Button>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={6} style={{ paddingTop: '30px', paddingLeft: '0px' }}>
                                <Typography variant="h6">Ratings Chart</Typography>
                            </Grid>
                            <Grid item xs={6} style={{ paddingTop: '10px', textAlign: 'center' }}>
                                <FormControl>
                                    <InputLabel htmlFor="chart-dates-range">Chart Dates range</InputLabel>
                                    <Select
                                        native
                                        onChange={chartDateRangeChanged}
                                        style={{ minWidth: '200px' }}
                                        value={chartDateRangeId}
                                    >
                                        {
                                            chartDateRanges.map((chartDateRange, index) => {
                                                return <option key={index} value={chartDateRange.id}>{chartDateRange.label}</option>
                                            })
                                        }
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} style={{ paddingTop: '10px', marginLeft: '-60px' }}>
                                <ResponsiveContainer width="100%" aspect={3}>
                                    <LineChart
                                        // width={900}
                                        // height={300}
                                        data={averagesByMonthFiltered}
                                        margin={{
                                            top: 0,
                                            right: 0,
                                            left: 0,
                                            bottom: 0,
                                        }}
                                    >
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <XAxis dataKey="dateText" />
                                        <YAxis yAxisId={1} type="number" domain={[0, 5]} label={{ value: 'Rating', angle: -90 }} />
                                        <YAxis yAxisId={2} orientation="right" label={{ value: 'Count', angle: -90 }} />
                                        <Tooltip />
                                        <Legend wrapperStyle={{ top: 0, left: 25 }} />
                                        <Line
                                            yAxisId={1}
                                            type="monotone"
                                            dataKey="Rating"
                                            stroke="#f5b63f"
                                            dot={false}
                                        />
                                        <Line
                                            yAxisId={1}
                                            type="monotone"
                                            dataKey="Average"
                                            stroke="#4153af"
                                            dot={false}
                                            activeDot={{ r: 6 }}
                                        />
                                        <Line
                                            yAxisId={2}
                                            type="monotone"
                                            dataKey="Count"
                                            stroke="#67ac5b"
                                            dot={false}
                                            activeDot={{ r: 6 }}
                                        />
                                        <ReferenceLine y={1} yAxisId={1} strokeDasharray="3 3" ifOverflow="extendDomain" />
                                        <ReferenceLine y={3} yAxisId={1} strokeDasharray="3 3" ifOverflow="extendDomain" />
                                        <ReferenceLine y={4} yAxisId={1} strokeDasharray="3 3" ifOverflow="extendDomain" />
                                    </LineChart>
                                </ResponsiveContainer>
                            </Grid>
                        </Grid>
                    </Container>
                </>
                :
                <UnderReview />
            }
        </>
    )
}

export default Dashboard;