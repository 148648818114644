import React, { useEffect, useState } from 'react';

import { useSelector } from "react-redux";

import { makeStyles } from '@material-ui/core/styles';

import { useAuth } from '../../contexts/AuthContext';

import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';

import UnderReview from './UnderReview';

const useStyles = makeStyles((theme) => ({
    list: {
        width: 250,
    },
    tenantsUploadInput: {
        display: 'none',
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    header: {
        backgroundColor: theme.palette.primary.light,
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
    lineTitle: {
        ...theme.typography.button,
        backgroundColor: theme.palette.background.paper,
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
    },
    section: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1)
    },
    table: {
        padding: theme.spacing(1),
        border: 'solid 1px',
        borderRadius: theme.spacing(1),
        borderColor: theme.palette.primary.light
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    success: {
        backgroundColor: theme.palette.success.main,
        color: theme.palette.common.white
    }
}));

const Settings = (props) => {
    const { currentUser } = useAuth();
    const [showPage, setShowPage] = useState(false);

    useEffect(() => {
        if (currentUser && currentUser.welcome) {
            setShowPage(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentUser]);

    const classes = useStyles();

    const [databaseStatisticsReviewsSupport, setDatabaseStatisticsReviewsSupport] = useState([]);

    const {
        sms,
        emails,
        notes,
        noteStatuses
    } = useSelector((state) => state);

    useEffect(() => {
        createReviewsSupportStatistics();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const createReviewsSupportStatistics = () => {
        setDatabaseStatisticsReviewsSupport([
            {
                id: 1,
                title: 'Saved Settings',
                value: '',
            },
            { id: 2, spacer: true },
            {
                id: 3,
                title: 'SMS',
                value: sms ? sms.length : '',
            },
            {
                id: 4,
                title: 'Emails',
                value: emails ? emails.length : '',
            },
            {
                id: 5,
                title: 'Notes',
                value: notes ? notes.length : '',
            },
            {
                id: 6,
                title: 'Notes for Status change',
                value: noteStatuses ? noteStatuses.length : '',
            },
        ]);
    }

    const TwoLinesTable = (props) => {
        const { items } = props;

        return (
            <Grid container className={classes.table}>
                {
                    items.map((item, index) => (
                        <TwoLinesTableRow key={item.id} item={item} />
                    ))
                }
            </Grid>
        )
    }

    const TwoLinesTableRow = (props) => {
        const { item } = props;

        return (
            <>
                {
                    item.spacer
                        ?
                        <Grid item xs={12}><hr /></Grid>
                        :
                        <>
                            <Grid item xs={11}>
                                <div className={classes.lineTitle}>{item.title}:</div>
                            </Grid>
                            <Grid item xs={1}>
                                <div className={classes.lineTitle}>{item.value}</div>
                            </Grid>
                        </>
                }
            </>
        )
    }

    return (
        <>
            {showPage
                ?
                <>
                    <Container maxWidth="lg">
                        <Grid container>
                            <Grid item xs={12} className={classes.section}>
                                <Grid container spacing={2}>
                                    <Grid item xs={4}>
                                        <TwoLinesTable items={databaseStatisticsReviewsSupport} />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Container>
                </>
                :
                <UnderReview />
            }
        </>
    )
}

export default Settings;