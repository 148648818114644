import { ACTIONS } from "../reducers"

export const updateOver90daysTenants = (list) => {
    return (dispatch) => {
        dispatch({
            type: ACTIONS.UPDATE_OVER_90_TENANTS,
            payload: list
        })
    }
}

export const updateOver90daysTenant = (item) => {
    return (dispatch) => {
        dispatch({
            type: ACTIONS.UPDATE_OVER_90_TENANT,
            payload: item
        })
    }
}