import React, { useState, useEffect } from 'react';
import PropertyTable from './property_table/PropertyTable';

const TenantList = (props) => {
    const {
        propertyName,
        showPropertyName,
        list,
        sources,
        properties,
        sms,
        emails,
        notes,
        noteStatuses,
        updateTenantCache,
        reviewCreated,
        smsCreated,
        smsUpdated,
        smsDeleted,
        emailCreated,
        emailUpdated,
        emailDeleted,
        noteCreated,
        noteUpdated,
        noteDeleted,
        noteStatusCreated,
        noteStatusUpdated,
        noteStatusDeleted
    } = props;

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [update, setUpdate] = useState(false);

    useEffect(() => {
    }, [update]);

    const historyUpdated = () => {
        setUpdate(!update);
    }

    const pageChanged = (event, newPage) => {
        setPage(newPage);
    };

    const rowsPerPageChanged = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    return (
        <PropertyTable
            key={propertyName}
            propertyName={propertyName}
            showPropertyName={showPropertyName}
            list={list}
            page={page}
            rowsPerPage={rowsPerPage}
            pageChanged={pageChanged}
            rowsPerPageChanged={rowsPerPageChanged}
            historyUpdated={historyUpdated}
            updateTenantCache={updateTenantCache}
            sources={sources}
            properties={properties}
            sms={sms}
            emails={emails}
            notes={notes}
            noteStatuses={noteStatuses}
            reviewCreated={reviewCreated}
            smsCreated={smsCreated}
            smsUpdated={smsUpdated}
            smsDeleted={smsDeleted}
            emailCreated={emailCreated}
            emailUpdated={emailUpdated}
            emailDeleted={emailDeleted}
            noteCreated={noteCreated}
            noteUpdated={noteUpdated}
            noteDeleted={noteDeleted}
            noteStatusCreated={noteStatusCreated}
            noteStatusUpdated={noteStatusUpdated}
            noteStatusDeleted={noteStatusDeleted}
        />
    );
}

export default TenantList;
