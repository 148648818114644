import { combineReducers } from "redux";
import tenantReducer from './tenantReducer';
import currentTenantReducer from './currentTenantReducer';
import first30TenantReducer from './first30TenantReducer';
import first3090TenantReducer from './first3090TenantReducer';
import futureTenantReducer from './futureTenantReducer';
import noticeTenantReducer from './noticeTenantReducer';
import over90TenantReducer from './over90TenantReducer';
import reviewReducer from './reviewReducer';
import sourceReducer from './sourceReducer';
import propertyReducer from './propertyReducer';
import smsReducer from './smsReducer';
import emailReducer from './emailReducer';
import noteReducer from './noteReducer';
import noteStatusReducer from './noteStatusReducer';
import uiReducer from './uiReducer';
import userReducer from './userReducer';

export const ACTIONS = {
    UPDATE_TENANTS: 'update-tenants',
    UPDATE_TENANT: 'update-tenant',
    UPDATE_CURRENT_TENANTS: 'update-current-tenants',
    UPDATE_CURRENT_TENANT: 'update-current-tenant',
    UPDATE_FIRST_30_TENANTS: 'update-first-30-tenants',
    UPDATE_FIRST_30_TENANT: 'update-first-30-tenant',
    UPDATE_FIRST_30_90_TENANTS: 'update-first-30-90-tenants',
    UPDATE_FIRST_30_90_TENANT: 'update-first-30-90-tenant',
    UPDATE_NOTICE_TENANTS: 'update-notice-tenants',
    UPDATE_NOTICE_TENANT: 'update-notice-tenant',
    UPDATE_FUTURE_TENANTS: 'update-future-tenants',
    UPDATE_FUTURE_TENANT: 'update-future-tenant',
    UPDATE_OVER_90_TENANTS: 'update-over-90-tenants',
    UPDATE_OVER_90_TENANT: 'update-over-90-tenant',
    UPDATE_REVIEWS: 'update-reviews',
    CREATE_REVIEW: 'create-review',
    DELETE_REVIEW: 'delete-review',
    UPDATE_SOURCES: 'update-sources',
    UPDATE_PROPERTIES: 'update-properties',
    UPDATE_SMSES: 'update-smses',
    CREATE_SMS: 'create-sms',
    UPDATE_SMS: 'update-sms',
    DELETE_SMS: 'delete-sms',
    UPDATE_EMAILS: 'update-emails',
    CREATE_EMAIL: 'create-email',
    UPDATE_EMAIL: 'update-email',
    DELETE_EMAIL: 'delete-email',
    UPDATE_NOTES: 'update-notes',
    CREATE_NOTE: 'create-note',
    UPDATE_NOTE: 'update-note',
    DELETE_NOTE: 'delete-note',
    UPDATE_NOTE_STATUSES: 'update-note-statuses',
    CREATE_NOTE_STATUS: 'create-note-status',
    UPDATE_NOTE_STATUS: 'update-note-status',
    DELETE_NOTE_STATUS: 'delete-note-status',
    UPDATE_UI: 'update-ui',
    UPDATE_USER: 'update-user',
}

const reducers = combineReducers({
    tenants: tenantReducer,
    currentTenants: currentTenantReducer,
    first30Tenants: first30TenantReducer,
    first3090Tenants: first3090TenantReducer,
    futureTenants: futureTenantReducer,
    noticeTenants: noticeTenantReducer,
    over90Tenants: over90TenantReducer,
    reviews: reviewReducer,
    sources: sourceReducer,
    properties: propertyReducer,
    sms: smsReducer,
    emails: emailReducer,
    notes: noteReducer,
    noteStatuses: noteStatusReducer,
    ui: uiReducer,
    user: userReducer
});

export default reducers;