
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
    footer: {
        backgroundColor: theme.palette.primary.light,
        color: theme.palette.common.white,
        padding: theme.spacing(1)
    },
    link: {
        textAlign: 'center'
    },
}));

const Footer = () => {
    const classes = useStyles();

    function getCurrentYear() {
        return new Date().getFullYear();
    }

    return (
        <Container className={classes.footer} style={{ minWidth: '100%' }}>
            <Grid container>
                <Grid item xs={12}>
                    <Typography className={classes.link}>©{getCurrentYear()} Get Virtual View</Typography>
                </Grid>
            </Grid>
        </Container>
    )
}

export default Footer;
