import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import reducers from "./reducers";
import { loadState, saveState } from "./localStorage"
import throttle from 'lodash.throttle';

const enhancers = [];
const middleware = [thunk];

const composedEnhancers = compose(
    applyMiddleware(...middleware),
    ...enhancers
)

const persistedState = loadState();

export const store = createStore(
    reducers,
    persistedState,
    composedEnhancers
)

store.subscribe(throttle(() => {
    saveState({
        tenants: store.getState().tenants,
        currentTenants: store.getState().currentTenants,
        first30Tenants: store.getState().first30Tenants,
        first3090Tenants: store.getState().first3090Tenants,
        futureTenants: store.getState().futureTenants,
        noticeTenants: store.getState().noticeTenants,
        over90Tenants: store.getState().over90Tenants,
        reviews: store.getState().reviews,
        sources: store.getState().sources,
        properties: store.getState().properties,
        sms: store.getState().sms,
        emails: store.getState().emails,
        notes: store.getState().notes,
        noteStatuses: store.getState().noteStatuses,
        ui: store.getState().ui,
        user: store.getState().user,
    });
}, 1000));