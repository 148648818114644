import { ACTIONS } from "../reducers"

export const updateNoteStatuses = (list) => {
    return (dispatch) => {
        dispatch({
            type: ACTIONS.UPDATE_NOTE_STATUSES,
            payload: list
        })
    }
}

export const createNoteStatus = (item) => {
    return (dispatch) => {
        dispatch({
            type: ACTIONS.CREATE_NOTE_STATUS,
            payload: item
        })
    }
}

export const updateNoteStatus = (item) => {
    return (dispatch) => {
        dispatch({
            type: ACTIONS.UPDATE_NOTE_STATUS,
            payload: item
        })
    }
}

export const deleteNoteStatus = (item) => {
    return (dispatch) => {
        dispatch({
            type: ACTIONS.DELETE_NOTE_STATUS,
            payload: item
        })
    }
}