import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { useAuth } from '../../contexts/AuthContext';

import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import Snackbar from '@material-ui/core/Snackbar';

import MuiAlert from '@material-ui/lab/Alert';

import AccountCircle from '@material-ui/icons/AccountCircle';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    title: {
        flexGrow: 1,
    },
}));


const Header = () => {
    const classes = useStyles();

    const [error, setError] = useState();
    const [message, setMessage] = useState();
    const { currentUser, logout } = useAuth();
    const history = useHistory();

    async function logoutUser(e) {
        setError('');

        try {
            await logout();

            setMessage('You have successfully logged out');
            setNotification({ ...notification, open: true });

            history.push('/login');
        } catch (error) {
            console.log(`Failed to log out ${error.message}`)

            setError(`Failed to log out: ${error.message}`)
            setNotification({ ...notification, open: true });
        }
    }

    const [notification, setNotification] = useState({
        open: false,
        vertical: 'top',
        horizontal: 'center',
    });

    const { open, vertical, horizontal } = notification;

    const closeNotification = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setNotification({ ...notification, open: false });
    };

    return (
        <>
            <Snackbar
                anchorOrigin={{ vertical, horizontal }}
                open={open}
                autoHideDuration={error ? 6000 : 3000}
                onClose={closeNotification}
            >
                <Alert
                    onClose={closeNotification}
                    severity={error ? "error" : "success"}>
                    {error}
                    {message}
                </Alert>
            </Snackbar>

            <div className={classes.root}>
                <AppBar position="static">
                    <Toolbar>
                        <Typography variant="h6" className={classes.title}>
                            Ratings Tracker
                        </Typography>
                        {currentUser &&
                            <Button color="inherit" onClick={logoutUser}>Log Out</Button>
                        }
                        {currentUser &&
                            <Link href="/update-profile" color="inherit" style={{ marginTop: '3px' }}><AccountCircle /></Link>
                        }
                    </Toolbar>
                </AppBar>
            </div>
        </>
    );
}

export default Header;
