import { ACTIONS } from "./"

const reducer = (state = {}, action) => {
    switch (action.type) {
        case ACTIONS.UPDATE_CURRENT_TENANTS:
            return action.payload;
        case ACTIONS.UPDATE_CURRENT_TENANT:
            const tenant = action.payload;

            return state.map(item => {
                if (item.id === tenant.id) {
                    return tenant;
                }
                return item;
            });
        default:
            return state;
    }
}

export default reducer;