import React, { useState, useEffect } from 'react';

import clsx from 'clsx';
import PropTypes from 'prop-types';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import InputBase from '@material-ui/core/InputBase';
import SearchIcon from '@material-ui/icons/Search';
import Snackbar from '@material-ui/core/Snackbar';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import MuiAlert from '@material-ui/lab/Alert';

import { makeStyles } from '@material-ui/core/styles';
import { alpha } from '@material-ui/core/styles';

import PropertyTableRow from './PropertyTableRow';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    titleBar: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        color: theme.palette.common.white,
        backgroundColor: theme.palette.primary.light,
        boxShadow: "none",
        borderBottom: "1px solid",
        borderBottomColor: theme.palette.grey.A200
    },
    title: {
        flexGrow: 1,
        display: 'none',
        [theme.breakpoints.up('sm')]: {
            display: 'block',
        },
    },
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: alpha(theme.palette.common.white, 0.15),
        '&:hover': {
            backgroundColor: alpha(theme.palette.common.white, 0.25),
        },
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(1),
            width: 'auto',
        },
    },
    searchIcon: {
        padding: theme.spacing(0, 2),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    inputRoot: {
        color: 'inherit',
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 0),
        paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: '12ch',
            '&:focus': {
                width: '20ch',
            },
        },
    },
    paper: {
        boxShadow: '0px 1px 1px -1px rgb(0 0 0 / 10%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(63 81 181 / 12%)'
    },
    checkBoxRoot: {
        '&:hover': {
            backgroundColor: 'transparent',
        },
    },
    icon: {
        borderRadius: 3,
        width: 16,
        height: 16,
        boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
        backgroundColor: theme.palette.common.white,
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
        '$root.Mui-focusVisible &': {
            outline: '2px auto rgba(19,124,189,.6)',
            outlineOffset: 2,
        },
        'input:hover ~ &': {
            backgroundColor: '#ebf1f5',
        },
        'input:disabled ~ &': {
            boxShadow: 'none',
            background: 'rgba(206,217,224,.5)',
        },
    },
    checkedIcon: {
        backgroundColor: theme.palette.success.main,
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
        '&:before': {
            display: 'block',
            width: 16,
            height: 16,
            backgroundImage:
                "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
                " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
                "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
            content: '""',
        },
        'input:hover ~ &': {
            backgroundColor: theme.palette.success.main,
        },
    },
}));

function StyledCheckbox(props) {
    const classes = useStyles();

    return (
        <Checkbox
            className={classes.checkBoxRoot}
            disableRipple
            color="default"
            checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
            icon={<span className={classes.icon} />}
            inputProps={{ 'aria-label': 'decorative checkbox' }}
            {...props}
        />
    );
}

const headCells = [
    { id: 'propertyName', numeric: false, disablePadding: true, label: 'Property' },
    { id: 'unit', numeric: false, disablePadding: true, label: 'Unit' },
    { id: 'tenant', numeric: false, disablePadding: false, label: 'Tenant' },
    { id: 'phoneNumbers', numeric: false, disablePadding: false, label: 'Phone #' },
    { id: 'emails', numeric: false, disablePadding: false, label: 'Emails' },
    { id: 'moveIn', numeric: false, disablePadding: false, label: 'Move In', minWidth: 120 },
    { id: 'moveOut', numeric: false, disablePadding: false, label: 'Move Out', minWidth: 120 },
    { id: 'lastAction', numeric: false, disablePadding: false, label: 'Last Action', minWidth: 150 },
    { id: 'batch', numeric: false, disablePadding: false, label: 'Batch', minWidth: 150 },
];

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

const PropertyTable = (props) => {
    const {
        propertyName,
        showPropertyName,
        list,
        page,
        rowsPerPage,
        pageChanged,
        rowsPerPageChanged,
        historyUpdated,
        updateTenantCache,
        sources,
        properties,
        sms,
        emails,
        notes,
        noteStatuses,
        reviewCreated,
        smsCreated,
        smsUpdated,
        smsDeleted,
        emailCreated,
        emailUpdated,
        emailDeleted,
        noteCreated,
        noteUpdated,
        noteDeleted,
        noteStatusCreated,
        noteStatusUpdated,
        noteStatusDeleted
    } = props;
    const classes = useStyles();

    function EnhancedTableHead(props) {
        const { order, orderBy, onRequestSort } = props;
        const createSortHandler = (property) => (event) => {
            onRequestSort(event, property);
        };

        return (
            <TableHead>
                <TableRow>
                    <TableCell />
                    {headCellsList.map((headCell) => (
                        <TableCell
                            key={headCell.id}
                            align={headCell.numeric ? 'right' : 'left'}
                            padding={headCell.disablePadding ? 'none' : 'normal'}
                            sortDirection={orderBy === headCell.id ? order : false}
                            style={{ minWidth: headCell.minWidth }}
                        >
                            <TableSortLabel
                                active={orderBy === headCell.id}
                                direction={orderBy === headCell.id ? order : 'asc'}
                                onClick={createSortHandler(headCell.id)}
                            >
                                {headCell.label}
                            </TableSortLabel>
                        </TableCell>
                    ))}
                </TableRow>
            </TableHead>
        );
    }

    EnhancedTableHead.propTypes = {
        classes: PropTypes.object.isRequired,
        onRequestSort: PropTypes.func.isRequired,
        order: PropTypes.oneOf(['asc', 'desc']).isRequired,
        orderBy: PropTypes.string.isRequired,
    };

    // Alert

    const [error, setError] = useState();
    const [message, setMessage] = useState();

    const [notification, setNotification] = useState({
        open: false,
        vertical: 'top',
        horizontal: 'center',
    });

    const { open, vertical, horizontal } = notification;

    const closeNotification = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setNotification({ ...notification, open: false });
    };

    // Table
    const [headCellsList, setHeadCellsList] = useState([]);
    const [searchParam, setSearchParam] = useState('');
    const [filteredList, setFilteredList] = useState([]);
    const [order, setOrder] = useState('desc');
    const [orderBy, setOrderBy] = useState('moveOut');
    const [showHidden, setShowHidden] = useState(false);

    const sortChanged = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    function searchChanged(event) {
        setSearchParam(event.target.value);
    }

    useEffect(() => {
        const filtered = getFilteredList();
        setFilteredList(filtered);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [list, searchParam, showHidden]);

    useEffect(() => {
        const filtered = showPropertyName ? headCells : headCells.filter(item => item.id !== 'propertyName')
        setHeadCellsList(filtered);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showPropertyName]);

    const getFilteredList = () => {
        if (list === undefined || list instanceof Array === false) {
            return [];
        }

        const search = searchParam.toLowerCase();
        return list.filter(item =>
            (item.tenant.toLowerCase().includes(search) ||
                item.emailsSearch.toLowerCase().includes(search) ||
                item.lastAction.toLowerCase().includes(search)) &&
            item.show !== showHidden
        );
    }

    const notificationUpdated = (response) => {
        if (response) {
            setError(!response.result);
            setMessage(response.message);
            setNotification({ ...notification, open: true });
        }
    }

    // Title bar

    const showAllChanged = (event) => {
        setShowHidden(event.target.checked);
    };

    return (
        <>
            <Snackbar
                anchorOrigin={{ vertical, horizontal }}
                open={open}
                autoHideDuration={error ? 6000 : 3000}
                onClose={closeNotification}
            >
                <Alert
                    onClose={closeNotification}
                    severity={error ? "error" : "success"}
                >
                    {message}
                </Alert>
            </Snackbar>

            <div className={classes.root}>
                <AppBar position="static" className={classes.titleBar}>
                    <Toolbar disableGutters={true}>
                        <Typography className={classes.title} variant="h6" noWrap>
                            {propertyName}
                        </Typography>
                        <FormControlLabel
                            control={
                                <StyledCheckbox
                                    checked={showHidden}
                                    onChange={showAllChanged}
                                    name="showAll"
                                />
                            }
                            label="Show Hidden"
                        />
                        <div className={classes.search}>
                            <div className={classes.searchIcon}>
                                <SearchIcon />
                            </div>
                            <InputBase
                                placeholder="Search…"
                                classes={{
                                    root: classes.inputRoot,
                                    input: classes.inputInput,
                                }}
                                inputProps={{ 'aria-label': 'search' }}
                                value={searchParam}
                                onChange={searchChanged}
                            />
                        </div>
                    </Toolbar>
                </AppBar>
            </div>
            <Paper className={classes.paper}>
                <TableContainer>
                    <Table>
                        <EnhancedTableHead
                            classes={classes}
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={sortChanged}
                        />
                        <TableBody>
                            {stableSort(filteredList, getComparator(order, orderBy))
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row) => (
                                    <PropertyTableRow
                                        key={row.id}
                                        headCells={headCellsList}
                                        showPropertyName={showPropertyName}
                                        row={row}
                                        historyUpdated={historyUpdated}
                                        notificationUpdated={notificationUpdated}
                                        updateTenantCache={updateTenantCache}
                                        sources={sources}
                                        properties={properties}
                                        sms={sms}
                                        emails={emails}
                                        notes={notes}
                                        noteStatuses={noteStatuses}
                                        reviewCreated={reviewCreated}
                                        smsCreated={smsCreated}
                                        smsUpdated={smsUpdated}
                                        smsDeleted={smsDeleted}
                                        emailCreated={emailCreated}
                                        emailUpdated={emailUpdated}
                                        emailDeleted={emailDeleted}
                                        noteCreated={noteCreated}
                                        noteUpdated={noteUpdated}
                                        noteDeleted={noteDeleted}
                                        noteStatusCreated={noteStatusCreated}
                                        noteStatusUpdated={noteStatusUpdated}
                                        noteStatusDeleted={noteStatusDeleted}
                                    />
                                ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
            <TablePagination
                rowsPerPageOptions={[5, 10, 25, 100]}
                component="div"
                count={filteredList.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={pageChanged}
                onRowsPerPageChange={rowsPerPageChanged}
            />
        </>
    );
}

PropertyTable.propTypes = {
    propertyName: PropTypes.string.isRequired,
    list: PropTypes.any.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
    pageChanged: PropTypes.func.isRequired,
    historyUpdated: PropTypes.func.isRequired,
    updateTenantCache: PropTypes.func.isRequired,
    sources: PropTypes.any.isRequired,
    properties: PropTypes.any.isRequired
};

export default PropertyTable;