import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import { AuthProvider } from '../contexts/AuthContext';

import PrivateRoute from './PrivateRoute';

import Register from './login/Register';
import Login from './login/Login';
import ForgotPassword from './login/ForgotPassword';
import UpdateProfile from './login/UpdateProfile';

import Header from './pages/Header';
import Footer from './pages/Footer';
import Navigation from './pages/Navigation';

import Dashboard from './pages/Dashboard';
import All from './pages/All';
import Current from './pages/Current';
import First30Days from './pages/First30Days';
import First3090Days from './pages/First3090Days';
import Over90Days from './pages/Over90Days';
import Notice from './pages/Notice';
import Future from './pages/Future';
import Reviews from './pages/Reviews';
import Settings from './pages/Settings';
import Admin from './pages/Admin';

import Container from '@material-ui/core/Container';

const App = () => {
  return (
    <>
      <div style={{
        position: 'absolute',
        top: '0',
        left: '0',
        width: '100%',
        height: '100%'
      }}>
        <Router>
          <AuthProvider>
            <Header />
            <Navigation />
            <Switch>
              <PrivateRoute exact path="/" component={Dashboard} />
              <PrivateRoute exact path="/all" component={All} />
              <PrivateRoute exact path="/current" component={Current} />
              <PrivateRoute exact path="/first-30-days" component={First30Days} />
              <PrivateRoute exact path="/first-30-90-days" component={First3090Days} />
              <PrivateRoute exact path="/over-90-days" component={Over90Days} />
              <PrivateRoute exact path="/notice" component={Notice} />
              <PrivateRoute exact path="/future" component={Future} />
              <PrivateRoute exact path="/reviews" component={Reviews} />
              <PrivateRoute exact path="/settings" component={Settings} />
            </Switch>
            <Container maxWidth="xs">
              <div style={{ maxWidth: '400px', minWidth: '400px', paddingTop: '100px' }}>
                <Switch>
                  <PrivateRoute path="/update-profile" component={UpdateProfile} />
                  <Route path="/register" component={Register} />
                  <Route path="/login" component={Login} />
                  <Route path="/forgot-password" component={ForgotPassword} />
                  <PrivateRoute exact path="/admin" component={Admin} />
                </Switch>
              </div>
            </Container>
            <div style={{
              position: 'fixed',
              bottom: '0',
              left: '0',
              width: '100%'
            }}>
              <Footer />
            </div>
          </AuthProvider>
        </Router>
      </div>
    </>
  )
}

export default App;