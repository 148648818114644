import { ACTIONS } from "../reducers"

export const updateNotes = (list) => {
    return (dispatch) => {
        dispatch({
            type: ACTIONS.UPDATE_NOTES,
            payload: list
        })
    }
}

export const createNote = (item) => {
    return (dispatch) => {
        dispatch({
            type: ACTIONS.CREATE_NOTE,
            payload: item
        })
    }
}

export const updateNote = (item) => {
    return (dispatch) => {
        dispatch({
            type: ACTIONS.UPDATE_NOTE,
            payload: item
        })
    }
}

export const deleteNote = (item) => {
    return (dispatch) => {
        dispatch({
            type: ACTIONS.DELETE_NOTE,
            payload: item
        })
    }
}