import { ACTIONS } from "../reducers"

export const updateSmses = (list) => {
    return (dispatch) => {
        dispatch({
            type: ACTIONS.UPDATE_SMSES,
            payload: list
        })
    }
}

export const createSms = (item) => {
    return (dispatch) => {
        dispatch({
            type: ACTIONS.CREATE_SMS,
            payload: item
        })
    }
}

export const updateSms = (item) => {
    return (dispatch) => {
        dispatch({
            type: ACTIONS.UPDATE_SMS,
            payload: item
        })
    }
}

export const deleteSms = (item) => {
    return (dispatch) => {
        dispatch({
            type: ACTIONS.DELETE_SMS,
            payload: item
        })
    }
}