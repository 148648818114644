function updateText(body, tenant, properties) {
    // console.log(`body: ${body}, tenant: ${JSON.stringify(tenant)}, properties: ${JSON.stringify(properties)}`);

    const property = properties.find(item => item.name === tenant.propertyName);

    const name = getName(tenant ? tenant.tenant : '');
    let bodyText = body.replace(/##first-name##/g, name ? name.firstName : '');
    bodyText = bodyText.replace(/##property-name##/g, property ? property.fullName : '');
    bodyText = bodyText.replace(/##google-review-link##/g, property ? property.googleReviewLink : '');
    bodyText = bodyText.replace(/##web-site##/g, property ? property.webSite : '');

    let user = JSON.parse(localStorage.getItem('user'));

    let userName;
    if (user instanceof Array === true && user.length > 0) {
        user = user[0];
        userName = getName(user.name);
    }

    bodyText = bodyText.replace(/##user-first-name##/g, userName !== undefined ? userName.firstName : '');

    let signature = [];
    signature.push(property ? property.fullName : '');
    signature.push(`Phone: ${property ? property.phone : ''}`);
    signature.push(`Email: ${property ? property.email : ''}`);
    signature = signature.join('\n');

    bodyText = bodyText.replace(/##signature##/g, signature);

    let userSignature = [];
    userSignature.push(userName !== undefined ? userName.firstName : '');
    userSignature.push(`Phone: ${user ? user.phone : ''}`);
    userSignature.push(`Email: ${user ? user.email : ''}`);
    userSignature = userSignature.join('\n');

    bodyText = bodyText.replace(/##user-signature##/g, userSignature);

    return bodyText;
}

const getName = (name) => {
    if (name.includes(',')) {
        const nameArr = name.split(',');

        if (nameArr.length >= 2) {
            let firstName = nameArr[1].trim();

            if (firstName.includes(' ')) {
                const firstNameArr = firstName.split(' ');

                if (firstNameArr.length >= 2) {
                    firstName = firstNameArr[0];
                }
            }

            return {
                firstName: firstName,
                lastName: nameArr[0].trim(),
                fullName: `${firstName} ${nameArr[0].trim()}`
            }
        }
    }

    return null;
}

export { updateText };