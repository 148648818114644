import React, { useState } from 'react';

import { register } from "../../services/firebase";

import { makeStyles } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';

import MuiAlert from '@material-ui/lab/Alert';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
    paper: {
        width: "100%",
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        borderRadius: theme.spacing(1),
    },
    form: {
        padding: theme.spacing(3),
    },
    margin: {
        marginTop: theme.spacing(1),
    },
    textField: {
        width: '25ch',
    },
    input: {
        width: '100%'
    },
    actionButton: {
        width: '100%'
    },
}));

const Admin = () => {
    const classes = useStyles();

    const [uid, setUid] = useState('');
    const [error, setError] = useState();
    const [message, setMessage] = useState();

    function idChanged(event) {
        setUid(event.target.value);
    };

    async function registerUser(status) {
        setError('');

        const response = await register({ uid: uid, status: status });
        if (response.result) {
            setMessage(response.message);
            setNotification({ ...notification, open: true });
        }
        else {
            setError(`Failed to register: ${response.message}`)
            setNotification({ ...notification, open: true });
        }

        setUid('');
    }

    const [notification, setNotification] = useState({
        open: false,
        vertical: 'top',
        horizontal: 'center',
    });

    const { open, vertical, horizontal } = notification;

    const closeNotification = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setNotification({ ...notification, open: false });
    };

    return (
        <>
            <Snackbar
                anchorOrigin={{ vertical, horizontal }}
                open={open}
                autoHideDuration={error ? 6000 : 3000}
                onClose={closeNotification}
            >
                <Alert
                    onClose={closeNotification}
                    severity={error ? "error" : "success"}>
                    {error}
                    {message}
                </Alert>
            </Snackbar>

            <div className={classes.paper}>
                <Grid container
                    justifyContent="center"
                    className={classes.form}
                    alignItems="center">
                    <Grid item xs={12}>
                        <TextField
                            label="User"
                            value={uid}
                            className={classes.input}
                            onChange={idChanged}
                        />
                    </Grid>
                    <Grid item xs={6} style={{ paddingTop: 20 }}>
                        <Button
                            variant="contained"
                            color="primary"
                            disabled={!uid || uid.length === 0}
                            onClick={e => registerUser(true)}
                        >
                            Register
                        </Button>
                    </Grid>
                    <Grid item xs={6} style={{ paddingTop: 20 }}>
                        <Button
                            variant="contained"
                            color="primary"
                            disabled={!uid || uid.length === 0}
                            onClick={e => registerUser(false)}
                        >
                            Un-Register
                        </Button>
                    </Grid>
                </Grid>
            </div>
        </>
    );
}

export default Admin;