import React, { useState, useEffect } from 'react';

import { addTenantNote, getHistoryRecord, sendEmail, sendSMS, updateItem, addItem } from "../../../services/firebase"

import PropTypes from 'prop-types';

import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import MuiTableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import Button from '@material-ui/core/Button';

import { makeStyles } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core/styles';

import SendSmsDialog from './SendSmsDialog'
import SendEmailDialog from './SendEmailDialog'
import AddNoteDialog from './AddNoteDialog';
import AddReviewDialog from '../common/AddReviewDialog';
import HideTenantDialog from './HideTenantDialog';
import NoteCell from '../common/NoteCell';

const moment = require('moment');

const TableCell = withStyles({
    root: {
        borderBottom: 'none'
    }
})(MuiTableCell);

const HISTORY_ACTIONS = {
    NEW_NOTE: 'New Note',
    EMAIL_SENT: 'Email sent',
    SMS_SENT: 'SMS sent',
    HIDDEN: 'Hidden',
    ACTIVE: 'Active',
    REVIEW_POSTED: 'Review posted'
}

const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            borderBottom: 'unset',
            paddingTop: '12px',
            paddingBottom: '12px'
        },
    },
    actionButtons: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        color: theme.palette.common.white,
        backgroundColor: theme.palette.success.main
    },
    paper: {
        width: "100%",
    },
    dialog: {
        padding: theme.spacing(2)
    },
    historyRow: {
        backgroundColor: 'rgba(63, 81, 181, 0.05)'
    },
    historyRowHeader: {
        borderBottom: "1px solid",
        borderBottomColor: theme.palette.grey.A100
    },
    lineTitle: {
        ...theme.typography.button,
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
    },
    cell: {
        verticalAlign: 'top'
    }
}));

const PropertyTableRow = (props) => {
    const {
        headCells,
        showPropertyName,
        row,
        sources,
        properties,
        sms,
        emails,
        notes,
        noteStatuses,
        historyUpdated,
        notificationUpdated,
        updateTenantCache,
        reviewCreated,
        smsCreated,
        smsUpdated,
        smsDeleted,
        emailCreated,
        emailUpdated,
        emailDeleted,
        noteCreated,
        noteUpdated,
        noteDeleted,
        noteStatusCreated,
        noteStatusUpdated,
        noteStatusDeleted
    } = props;
    const [rowOpen, setRowOpen] = useState(false);
    const classes = useStyles();

    const [propertyNames, setPropertyNames] = useState([]);

    useEffect(() => {
        if (properties instanceof Array) {
            const list = properties
                .filter(item => item.active === true)
                .map(item => item.name).sort();

            setPropertyNames(list);
        }
    }, [properties]);

    useEffect(() => {
        if (row.history.length > 0) {
            row.history = row.history.sort((a, b) => b.createdOn - a.createdOn)
            row.lastAction = `${row.history[0].action} on ${moment(row.history[0].createdOn).format('L')}`;
        }
    }, [row]);

    const [tenant, setTenant] = useState('');

    // Send SMS

    const [showSendSmsDialog, setSendSmsDialog] = useState(false);

    const openSendSmsDialog = (item) => {
        setTenant(JSON.stringify(item));
        setSendSmsDialog(true);
    };

    const cancelSendSmsDialog = (value) => {
        setSendSmsDialog(false);
    };

    const closeSendSmsDialog = async (sms) => {
        setSendSmsDialog(false);

        sms.to = `+1${sms.to.replace(/[^0-9.]/g, '')}`;

        const response = await sendSMS(sms);

        const t = JSON.parse(tenant);
        await addHistoryRecord(t, HISTORY_ACTIONS.SMS_SENT, sms.body, sms.to);

        notificationUpdated(response);
    };

    // Send Email

    const [showSendEmailDialog, setSendEmailDialog] = useState(false);

    const openSendEmailDialog = (item) => {
        setTenant(JSON.stringify(item));
        setSendEmailDialog(true);
    };

    const cancelSendEmailDialog = (value) => {
        setSendEmailDialog(false);
    };

    const closeSendEmailDialog = async (email) => {
        setSendEmailDialog(false);

        const response = await sendEmail(email);

        const t = JSON.parse(tenant);
        await addHistoryRecord(t, HISTORY_ACTIONS.EMAIL_SENT, email.body, email);

        notificationUpdated(response);
    };

    // Add Note

    const [showAddNoteDialog, setShowAddNoteDialog] = useState(false);

    const openAddNoteDialog = (item) => {
        setTenant(JSON.stringify(item));
        setShowAddNoteDialog(true);
    };

    const cancelAddNoteDialog = (value) => {
        setShowAddNoteDialog(false);
    };

    const closeAddNoteDialog = (value) => {
        setShowAddNoteDialog(false);
        addNote(tenant, value);
    };

    const addNote = async (tenant, note) => {
        const t = JSON.parse(tenant);
        const message = 'New note added';

        await addHistoryRecord(t, HISTORY_ACTIONS.NEW_NOTE, note);
        notificationUpdated({ result: true, message: message });
    }

    // Add Review

    const [showAddReviewDialog, setShowAddReviewDialog] = useState(false);
    const [resetReview, setResetReview] = useState(false);

    const openAddReviewDialog = (item) => {
        setTenant(JSON.stringify(item));
        setShowAddReviewDialog(true);
    };

    const cancelAddReviewDialog = (value) => {
        setShowAddReviewDialog(false);
    };

    const closeAddReviewDialog = (source, property, reviewDate, name, rating, review) => {
        setShowAddReviewDialog(false);
        addReview(source, property, reviewDate, name, rating, review, tenant);
        setResetReview(!resetReview);
    };

    const addReview = async (source, property, reviewDate, name, rating, review, tenant) => {
        const t = JSON.parse(tenant);

        try {
            let itm = await addItem('reviews', {
                source: source,
                property: property,
                reviewDate: reviewDate,
                name: name,
                rating: rating,
                review: review,
                tenant: t.id,
                createdOn: new Date()
            });

            itm.reviewDateText = moment(itm.reviewDate).format('MM/DD/YY');

            reviewCreated(itm);
            await addHistoryRecord(t, HISTORY_ACTIONS.REVIEW_POSTED, `Review posted on ${source}, rating: ${rating}`);
            notificationUpdated({ result: true, message: 'New review added' });

            setResetReview(!resetReview);
        } catch (error) {
            console.log(`Error adding note: ${error.message} `);
        }
    }

    // Tenant

    const [showHideTenantDialog, setShowHideTenantDialog] = useState(false);

    const openHideTenantDialog = (item) => {
        setTenant(JSON.stringify(item));
        setShowHideTenantDialog(true);
    };

    const cancelHideTenantDialog = (value) => {
        setShowHideTenantDialog(false);
    };

    const closeHideTenantDialog = (value) => {
        setShowHideTenantDialog(false);
        hideTenant(tenant, value);
    };

    const hideTenant = async (tenant, note) => {
        const t = JSON.parse(tenant);

        t.show = !t.show;
        t.updatedOn = new Date();

        try {
            await updateItem('tenants', t);
        } catch (error) {
            console.log(`Error updating show status: ${error.message}`);
        }

        const message = `Tenant status changed to ${t.show ? HISTORY_ACTIONS.ACTIVE : HISTORY_ACTIONS.HIDDEN}`;

        await addHistoryRecord(t, t.show ? HISTORY_ACTIONS.ACTIVE : HISTORY_ACTIONS.HIDDEN, message);
        notificationUpdated({ result: true, message: message });
    }

    // History

    const addHistoryRecord = async (tenant, action, note, to) => {
        try {
            let itm = await addTenantNote({
                tenant: tenant.id,
                action: action,
                note: note,
                createdOn: new Date(),
                to: to !== undefined ? to : ''
            });

            itm = getHistoryRecord(itm);

            tenant.history.unshift(itm);

            updateTenantCache(tenant);
            row.history.unshift(itm);

            historyUpdated();
        } catch (error) {
            console.log(`Error adding note: ${error.message} `);
        }
    }

    return (
        <>
            <SendSmsDialog
                tenant={tenant}
                sms={sms}
                open={showSendSmsDialog}
                onCancel={cancelSendSmsDialog}
                onClose={closeSendSmsDialog}
                smsCreated={smsCreated}
                smsUpdated={smsUpdated}
                smsDeleted={smsDeleted}
                properties={properties}
            />
            <SendEmailDialog
                tenant={tenant}
                emails={emails}
                open={showSendEmailDialog}
                onCancel={cancelSendEmailDialog}
                onClose={closeSendEmailDialog}
                emailCreated={emailCreated}
                emailUpdated={emailUpdated}
                emailDeleted={emailDeleted}
                properties={properties}
            />
            <AddNoteDialog
                tenant={tenant}
                notes={notes}
                open={showAddNoteDialog}
                onCancel={cancelAddNoteDialog}
                onClose={closeAddNoteDialog}
                noteCreated={noteCreated}
                noteUpdated={noteUpdated}
                noteDeleted={noteDeleted}
            />
            <AddReviewDialog
                tenant={tenant}
                sources={sources}
                properties={propertyNames}
                open={showAddReviewDialog}
                onCancel={cancelAddReviewDialog}
                onClose={closeAddReviewDialog}
                resetReview={resetReview}
            />
            <HideTenantDialog
                tenant={tenant}
                noteStatuses={noteStatuses}
                open={showHideTenantDialog}
                onCancel={cancelHideTenantDialog}
                onClose={closeHideTenantDialog}
                noteStatusCreated={noteStatusCreated}
                noteStatusUpdated={noteStatusUpdated}
                noteStatusDeleted={noteStatusDeleted}
            />
            <TableRow className={classes.root}>
                <TableCell>
                    <IconButton aria-label="expand row" size="small" onClick={() => setRowOpen(!rowOpen)}>
                        {rowOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                {showPropertyName && <TableCell className={classes.cell}>{row.propertyName}</TableCell>}
                <TableCell className={classes.cell}>{row.unit}</TableCell>
                <TableCell className={classes.cell}>{row.tenant}</TableCell>
                <TableCell className={classes.cell}>
                    <Grid container>
                        {row.phoneNumbers &&
                            row.phoneNumbers.map((item, index) => {
                                return <Grid key={index} item xs={12}>{item.type && item.type.substring(0, 1).toUpperCase()}: {item.phone}</Grid>
                            })
                        }
                    </Grid>
                </TableCell>
                <TableCell className={classes.cell}>
                    <Grid container>
                        {row.emails &&
                            row.emails.map((item, index) => {
                                return <Grid key={index} item xs={12}>{item}</Grid>
                            })
                        }
                    </Grid>
                </TableCell>
                <TableCell className={classes.cell}>{row.moveInText}</TableCell>
                <TableCell className={classes.cell}>{row.moveOutText}</TableCell>
                <TableCell className={classes.cell}>{row.lastAction}</TableCell>
                <TableCell className={classes.cell}>{row.batch}</TableCell>
            </TableRow>
            <TableRow className={classes.historyRow}>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={headCells.length + 1}>
                    <Collapse in={rowOpen} timeout="auto" unmountOnExit>
                        <Grid container style={{ paddingTop: 10 }}>
                            <Grid item xs={2}>
                                <Typography
                                    variant="h6"
                                    gutterBottom
                                    component="div"
                                    className={classes.lineTitle}
                                >
                                    History:
                                </Typography>
                            </Grid>
                            <Grid item xs={10} align="right">
                                <Button
                                    variant="contained"
                                    className={classes.actionButtons}
                                    disabled={!row.phoneNumbers || row.phoneNumbers.length === 0}
                                    onClick={() => { openSendSmsDialog(row) }}
                                >
                                    Send SMS
                                </Button>
                                <Button
                                    variant="contained"
                                    className={classes.actionButtons}
                                    disabled={!row.emails || row.emails.length === 0}
                                    onClick={() => { openSendEmailDialog(row) }}
                                >
                                    Send Email
                                </Button>
                                <Button
                                    variant="contained"
                                    className={classes.actionButtons}
                                    onClick={() => openAddNoteDialog(row)}
                                >
                                    Add Note
                                </Button>
                                <Button
                                    variant="contained"
                                    className={classes.actionButtons}
                                    onClick={() => openAddReviewDialog(row)}
                                >
                                    Add Review
                                </Button>
                                <Button
                                    variant="contained"
                                    className={classes.actionButtons}
                                    onClick={() => openHideTenantDialog(row)}
                                >
                                    {row.show ? "Hide" : "Show"}
                                </Button>
                            </Grid>
                        </Grid>
                        {row.history && row.history.length > 0
                            ?
                            <Box margin={1}>
                                <Table size="small" aria-label="actions">
                                    <TableHead className={classes.historyRowHeader}>
                                        <TableRow>
                                            <TableCell>Date</TableCell>
                                            <TableCell>Action</TableCell>
                                            <TableCell>Notes</TableCell>
                                            <TableCell>Updated By</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {row.history.map((historyRow, index) => (
                                            <TableRow key={index}>
                                                <TableCell component="th" scope="row" className={classes.cell}>
                                                    {historyRow.createdOnText}
                                                </TableCell>
                                                <TableCell className={classes.cell}>{historyRow.action}</TableCell>
                                                <TableCell><NoteCell content={historyRow.note}></NoteCell></TableCell>
                                                <TableCell className={classes.cell}>{historyRow.user}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </Box>
                            :
                            <Box style={{ paddingBottom: '8px' }} />
                        }
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    );
}

PropertyTableRow.propTypes = {
    headCells: PropTypes.any.isRequired,
    row: PropTypes.shape({
        unit: PropTypes.string.isRequired,
        tenant: PropTypes.string.isRequired,
        phoneNumbersText: PropTypes.string,
        emailsText: PropTypes.string,
        moveInText: PropTypes.string,
        moveOutText: PropTypes.string,
        history: PropTypes.arrayOf(
            PropTypes.shape({
                tenant: PropTypes.string.isRequired,
                action: PropTypes.string.isRequired,
                note: PropTypes.string.isRequired,
                reviewDateText: PropTypes.string,
            }),
        ),
    }).isRequired,
    sources: PropTypes.any.isRequired,
    properties: PropTypes.any.isRequired,
    historyUpdated: PropTypes.func.isRequired,
    notificationUpdated: PropTypes.func.isRequired,
    updateTenantCache: PropTypes.func.isRequired
};

export default PropertyTableRow;