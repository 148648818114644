import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
    container: {
        width: "50%",
        margin: "auto",
        padding: "70px 0",
    },
    paper: {
        width: "400px",
        margin: "auto",
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        borderRadius: theme.spacing(1),
    },
    form: {
        width: 400,
        padding: theme.spacing(3),
        textAlign: "center"
    },
}));

const UnderReview = () => {
    const classes = useStyles();

    return (
        <>
            <div className={classes.container}>
                <div className={classes.paper}>
                    <Grid container
                        justifyContent="center"
                        className={classes.form}
                        alignItems="center">
                        <Grid item xs={12}>
                            <Typography variant="h6" color="textSecondary">Account under review</Typography>
                        </Grid>
                    </Grid>
                </div>
            </div>
        </>
    );
}

export default UnderReview;