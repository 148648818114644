import { ACTIONS } from "./"

const reducer = (state = {}, action) => {
    switch (action.type) {
        case ACTIONS.UPDATE_REVIEWS:
            return action.payload;
        case ACTIONS.CREATE_REVIEW:
            return [...state, action.payload];
        case ACTIONS.DELETE_REVIEW:
            return state.filter(item => item.id !== action.payload.id);
        default:
            return state;
    }
}

export default reducer;