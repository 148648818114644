import { ACTIONS } from "./"

const reducer = (state = {}, action) => {
    switch (action.type) {
        case ACTIONS.UPDATE_EMAILS:
            return action.payload;
        case ACTIONS.CREATE_EMAIL:
            return [...state, action.payload];
        case ACTIONS.UPDATE_EMAIL:
            const itm = action.payload;

            return state.map(item => {
                if (item.id === itm.id) {
                    return itm;
                }
                return item;
            });
        case ACTIONS.DELETE_EMAIL:
            return state.filter(item => item.id !== action.payload.id);
        default:
            return state;
    }
}

export default reducer;