import firebase from 'firebase/app'
import "firebase/auth"
import "firebase/firestore";
import "firebase/functions";

const moment = require('moment');

const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID
};

const app = firebase.initializeApp(firebaseConfig);

const db = firebase.firestore();
const auth = app.auth();
const functions = firebase.functions();


auth.onAuthStateChanged(user => {
    if (user) {
        user.getIdTokenResult().then(async idTokenResult => {
            const registered = idTokenResult.claims.registered || user.email === 'anatoli@amnesia.net';

            if (registered) {
                user.welcome = true;
            }

            await getUser(user.email)
                .then(u => {
                    localStorage.setItem("user", JSON.stringify(u));
                })
        });
    }
    else {
        localStorage.removeItem("user");
    }
});

export async function register(item) {
    const register = functions.httpsCallable('register');

    return await register(item)
        .then(result => {
            if (result && result.data) {
                return result.data;
            }
            return { result: false, message: 'No response received' };
        })
        .catch((error) => {
            const code = error.code;
            const message = error.message;
            const details = error.details;

            const errMessage = `Error registring user. code: ${code}, message: ${message}, details: ${details}`;
            console.log(errMessage)

            return { result: false, message: errMessage };
        });
}

export async function getUser(email) {
    return db.collection('users')
        .where('email', '==', email)
        .get()
        .then(snapshot => {
            if (snapshot && snapshot.docs) {

                let list = [];

                snapshot.docs.forEach(doc => {
                    list.push({
                        ...doc.data(),
                        id: doc.id
                    });
                });

                return list;
            } else {
                // eslint-disable-next-line no-console
                console.log("No tenants found");
            }
        })
        .catch(err => {
            // eslint-disable-next-line no-console
            console.log("Error getting tenants: " + err.message);
        });
}

export async function getTenants() {
    return db.collection('tenants')
        .get()
        .then(snapshot => {
            if (snapshot && snapshot.docs) {

                let list = [];

                snapshot.docs.forEach(doc => {
                    list.push({
                        ...doc.data(),
                        id: doc.id
                    });
                });

                list.forEach(item => {
                    item = getTenantRecord(item);
                })

                return list;
            } else {
                // eslint-disable-next-line no-console
                console.log("No tenants found");
            }
        })
        .catch(err => {
            // eslint-disable-next-line no-console
            console.log("Error getting tenants: " + err.message);
        });
}

export function getTenantRecord(item) {
    if (item.emails) {
        item.emailsSearch = item.emails.join(' ');
    }

    if (item.phoneNumbers) {
        item.phoneNumbersSearch = item.phoneNumbers.map(item => item.phone).join(' ');
    }

    if (typeof (item.moveIn) === 'string') {
        const moveIn = item.moveIn ? new Date(item.moveIn) : item.moveIn;
        item.moveInText = moveIn ? moment(moveIn).format('L') : '';
        item.moveIn = moveIn;
    }
    else {
        const moveIn = item.moveIn ? new Date(item.moveIn.seconds * 1000) : item.moveIn;
        item.moveInText = moveIn ? moment(moveIn).format('L') : '';
        item.moveIn = moveIn;
    }

    if (typeof (item.moveOut) === 'string') {
        const moveOut = item.moveOut ? new Date(item.moveOut) : item.moveOut;
        item.moveOutText = moveOut ? moment(moveOut).format('L') : '';
        item.moveOut = moveOut;
    }
    else {
        const moveOut = item.moveOut ? new Date(item.moveOut.seconds * 1000) : item.moveOut;
        item.moveOutText = moveOut ? moment(moveOut).format('L') : '';
        item.moveOut = moveOut;
    }

    item.lastAction = '';
    item.history = [];

    return item;
}

export async function saveTenants(list) {
    const batchMax = 500;
    const batchId = Math.ceil(list.length / batchMax);

    for (let i = 0; i < batchId; i++) {
        const batch = db.batch();

        for (let j = 0; j < batchMax; j++) {
            const item = list[(batchMax * i) + j];

            if (item === undefined) {
                break;
            }

            let docRef;
            if (item.id) {
                docRef = db.collection('tenants').doc(item.id);
            }
            else {
                docRef = db.collection('tenants').doc();
            }

            batch.set(docRef, item);
        }

        await batch.commit();
    }
}

export async function addTenantNote(item) {
    item.user = auth.currentUser.email;

    return db.collection('history')
        .add(item)
        .then(doc => {
            item.id = doc.id;
            return item;
        })
        .catch(err => {
            // eslint-disable-next-line no-console
            console.log("Error adding note: " + err.message);
        });
}

export async function getHistory() {
    return db.collection('history')
        .get()
        .then(snapshot => {
            if (snapshot && snapshot.docs) {

                let list = [];

                snapshot.docs.forEach(doc => {
                    list.push({
                        ...doc.data(),
                        id: doc.id
                    });
                });

                list.forEach(item => {
                    item = getHistoryRecord(item);
                })

                return list;
            } else {
                // eslint-disable-next-line no-console
                console.log("No history found");
            }
        })
        .catch(err => {
            // eslint-disable-next-line no-console
            console.log("Error getting history: " + err.message);
        });
}

export function getHistoryRecord(item) {
    if (Object.prototype.toString.call(item.createdOn) !== '[object Date]') {
        item.createdOn = item.createdOn.toDate();
    }

    item.createdOnText = item.createdOn ? moment(item.createdOn).format('MM/DD/YY hh:mm a') : '';

    return item;
}

export async function getReviews() {
    return db.collection('reviews')
        .get()
        .then(snapshot => {
            if (snapshot && snapshot.docs) {

                let list = [];

                snapshot.docs.forEach(doc => {
                    list.push({
                        ...doc.data(),
                        id: doc.id
                    });
                });

                list.forEach(item => {
                    item = getReviewRecord(item);
                })

                return list;
            } else {
                // eslint-disable-next-line no-console
                console.log("No reviews found");
            }
        })
        .catch(err => {
            // eslint-disable-next-line no-console
            console.log("Error getting reviews: " + err.message);
        });
}

export function getReviewRecord(item) {
    if (Object.prototype.toString.call(item.reviewDate) !== '[object Date]') {
        item.reviewDate = item.reviewDate.toDate();
    }

    item.reviewDateText = item.reviewDate ? moment(item.reviewDate).format('MM/DD/YY') : '';

    if (item.tenant) {
        item.tenantText = '1';
    }
    else {
        item.tenantText = '0';
    }

    return item;
}

export async function addItem(collection, item) {
    item.user = auth.currentUser.email;

    return db.collection(collection)
        .add(item)
        .then(doc => {
            item.id = doc.id;
            return item;
        })
        .catch(err => {
            // eslint-disable-next-line no-console
            console.log("Error adding item: " + err.message);
        });
}

export async function updateItem(collection, item) {
    item.user = auth.currentUser.email;

    return db.collection(collection)
        .doc(item.id)
        .update(item)
        .then(doc => {
            return item;
        })
        .catch(err => {
            // eslint-disable-next-line no-console
            console.log("Error updating item: " + err.message);
        });
}

export async function deleteItem(collection, id) {
    return db.collection(collection)
        .doc(id)
        .delete()
        .then(() => { return null })
        .catch(err => {
            // eslint-disable-next-line no-console
            console.log("Error deleting item: " + err.message);
        });
}

export async function getItems(collection) {
    return db.collection(collection)
        .get()
        .then(snapshot => {
            if (snapshot && snapshot.docs) {

                let list = [];

                snapshot.docs.forEach(doc => {
                    list.push({
                        ...doc.data(),
                        id: doc.id
                    });
                });

                return list;
            } else {
                // eslint-disable-next-line no-console
                console.log("No items found");
                return [];
            }
        })
        .catch(err => {
            // eslint-disable-next-line no-console
            console.log("Error getting items: " + err.message);
            return [];
        });
}

export async function sendEmail(email) {
    const sendEmail = functions.httpsCallable('sendEmail');

    return await sendEmail(email)
        .then(result => {
            if (result && result.data) {
                return result.data;
            }
            return { result: false, message: 'No response received' };
        })
        .catch((error) => {
            const code = error.code;
            const message = error.message;
            const details = error.details;

            const errMessage = `Error sending email. code: ${code}, message: ${message}, details: ${details}`;
            console.log(errMessage)

            return { result: false, message: errMessage };
        });
}

export async function sendSMS(sms) {
    const sendSMS = functions.httpsCallable('sendSMS');

    return await sendSMS(sms)
        .then(result => {
            if (result && result.data) {
                return result.data;
            }
            return { result: false, message: 'No response received' };
        })
        .catch((error) => {
            const code = error.code;
            const message = error.message;
            const details = error.details;

            const errMessage = `Error sending sms. code: ${code}, message: ${message}, details: ${details}`;
            console.log(errMessage)

            return { result: false, message: errMessage };
        });
}

export { db, auth, functions }
export default app;