import React, { useState, useEffect } from 'react';

import { addItem, updateItem, deleteItem } from "../../../services/firebase"

import PropTypes from 'prop-types';

import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    noteButtons: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        color: theme.palette.common.white,
        backgroundColor: theme.palette.primary.main
    },
    cancelButton: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        color: theme.palette.common.white,
        backgroundColor: theme.palette.success.light
    },
    okButton: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        color: theme.palette.common.white,
        backgroundColor: theme.palette.success.main
    },
    dialog: {
        padding: theme.spacing(2)
    }
}));

const BUTTON_TEXT = {
    NEW: "NEW",
    CANCEL: "CANCEL",
    SAVE: "SAVE",
    UPDATE: "UPDATE",
    DELETE: "DELETE"
}

const AddNoteDialog = (props) => {
    const {
        tenant,
        notes,
        open,
        onCancel,
        onClose,
        noteCreated,
        noteUpdated,
        noteDeleted
    } = props;
    const collection = 'notes';

    const [noteId, setNoteId] = useState('');
    const [noteTitle, setNoteTitle] = useState('');
    const [note, setNote] = useState('');
    const [editMode, setEditMode] = useState(false);
    const [titleLength, setTitleLength] = useState(0);

    const classes = useStyles();

    useEffect(() => {
        editMode ? setTitleLength('700px') : setTitleLength('605px');
    }, [editMode]);

    const clear = () => {
        resetNote();
    };

    const cancel = () => {
        onCancel();
    };

    const close = () => {
        onClose(note);

        if (editMode) {
            setEditMode(false);
        }
        resetNote();
    };

    const noteSelected = (event) => {
        const value = event.target.value;

        const itm = notes.find(item => item.id === value);

        if (itm) {
            setNoteId(itm.id);
            setNoteTitle(itm.title);
            setNote(itm.text);
        }
        else {
            setNoteId('');
            setNoteTitle('');
            setNote('');
        }
    };

    const noteChanged = (event) => {
        setNote(event.target.value);
    }

    const newTitleChanged = (event) => {
        setNoteTitle(event.target.value);
    };

    const startNewNote = (event) => {
        setEditMode(!editMode);
        resetNote();
    }

    const saveNote = () => {
        if (editMode) {
            handleAddNote(noteTitle, note);
        }
        else {
            handleUpdateNote(noteId, noteTitle, note);
        }
    }

    const handleAddNote = async (title, text) => {
        try {
            const item = await addItem(collection, {
                title: title,
                text: text,
                createdOn: new Date()
            });

            if (item) {
                setEditMode(false);
                noteCreated(item);
                loadNote(item);
            }
        } catch (error) {
            console.log(`Error adding note: ${error.message}`);
        }
    }

    const handleUpdateNote = async (id, title, text) => {
        try {
            const item = await updateItem(collection, {
                id: id,
                title: title,
                text: text,
                updatedOn: new Date()
            });

            noteUpdated(item);

            if (editMode) {
                setEditMode(false);
            }
        } catch (error) {
            console.log(`Error updating note: ${error.message}`);
        }
    }

    const handleDeleteNote = async (id) => {
        // eslint-disable-next-line no-restricted-globals
        if (!confirm('Are you sure you want to delete this note?')) {
            return;
        }

        const itm = notes.find(item => item.id === id);
        if (!itm) {
            alert('This Note not found');
            return;
        }

        try {
            await deleteItem(collection, id);
            noteDeleted(itm);

            resetNote();
        } catch (error) {
            console.log(`Error deleting saved note: ${error.message}`);
        }
    }

    const loadNote = (item) => {
        setNoteId(item.id);
        setNoteTitle(item.title);
        setNote(item.text);
    }

    const resetNote = () => {
        setNoteId('');
        setNoteTitle('');
        setNote('');
    }

    const t = tenant ? JSON.parse(tenant) : {};

    return (
        <Dialog open={open} maxWidth="md">
            <Grid container justifyContent="center" alignItems="center" className={classes.dialog} spacing={2}>
                <Grid item xs={12} style={{ width: '600px' }}>
                    <Typography color="primary">{t.tenant}</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Grid container alignItems="flex-end">
                        <Grid item>
                            {!editMode ?
                                <FormControl>
                                    <InputLabel htmlFor="saved-notes">Saved Notes</InputLabel>
                                    <Select
                                        native
                                        onChange={noteSelected}
                                        style={{ minWidth: titleLength }}
                                        value={noteId}
                                    >
                                        <option aria-label="None" value="" />
                                        {
                                            notes.map(note => {
                                                return <option key={note.id} value={note.id}>{note.title}</option>
                                            })
                                        }
                                    </Select>
                                </FormControl>
                                :
                                <TextField
                                    label="New Note"
                                    style={{ minWidth: titleLength }}
                                    onChange={newTitleChanged}
                                />
                            }
                        </Grid>
                        <Grid item>
                            <Button
                                variant="contained"
                                className={editMode ? classes.cancelButton : classes.okButton}
                                onClick={startNewNote}
                            >
                                {editMode ? BUTTON_TEXT.CANCEL : BUTTON_TEXT.NEW}
                            </Button>
                            <Button
                                variant="contained"
                                className={classes.noteButtons}
                                disabled={editMode ? (noteTitle.length === 0 || note.length === 0) : !noteId}
                                onClick={() => saveNote()}
                            >
                                {editMode ? BUTTON_TEXT.SAVE : BUTTON_TEXT.UPDATE}
                            </Button>
                            {!editMode &&
                                <Button
                                    variant="contained"
                                    className={classes.noteButtons}
                                    disabled={!noteId}
                                    onClick={() => handleDeleteNote(noteId)}
                                >
                                    {BUTTON_TEXT.DELETE}
                                </Button>
                            }
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        size="medium"
                        variant="outlined"
                        multiline={true}
                        rows="5"
                        style={{ width: '100%' }}
                        value={note}
                        onChange={noteChanged}
                        autoFocus
                    />
                </Grid>
                <Grid item xs={12} style={{ textAlign: 'right' }}>
                    <Button
                        variant="contained"
                        className={classes.cancelButton}
                        onClick={() => clear()}
                    >
                        Clear
                    </Button>
                    <Button
                        variant="contained"
                        className={classes.cancelButton}
                        onClick={() => cancel()}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        className={classes.okButton}
                        disabled={note.length === 0}
                        onClick={() => close()}
                    >
                        Add Note
                    </Button>
                </Grid>
            </Grid>
        </Dialog>
    );
}

AddNoteDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    tenant: PropTypes.string.isRequired,
    onCancel: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    noteCreated: PropTypes.func.isRequired,
    noteUpdated: PropTypes.func.isRequired,
    noteDeleted: PropTypes.func.isRequired,
};
export default AddNoteDialog;